import React from 'react'

import WhatsAppIcon from './svgs/WhatsAppIcon'
import { classNames } from '../utilities/general'

const WhatsAppShareButton = ({ organisationName, surveyUrl, dark = false }) => {
  return (
    <a
      href={`https://wa.me/?text=${encodeURIComponent(
        `Please complete this survey about ${organisationName} - ${surveyUrl}`
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        dark ? 'border-gray-700 hover:bg-blue-950' : 'bg-white border-gray-300 hover:bg-gray-100',
        'inline-flex gap-2 items-center border   p-2 rounded text-sm'
      )}
    >
      <WhatsAppIcon className="h-6 w-6" />
      Share via WhatsApp
    </a>
  )
}

export default WhatsAppShareButton
