import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get, post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import SurveyListItem from './SurveyListItem'
import Button from '../../Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import useProducts from '../../../hooks/useProducts'
import ProductImage from '../../ProductImage'
import TrendSection from '../BusinessProcessSurveyListPage/TrendSection'

const ProductSurveyListPage = ({ cognitoUser, stack }) => {
  const [loadingStackSurveys, setLoadingStackSurveys] = useState()
  const [stackSurveys, setStackSurveys] = useState([])
  const { loadingProducts, products } = useProducts(true)
  const [moments, setMoments] = useState([])
  const [loadingMoments, setLoadingMoments] = useState(true)
  const { stackId, productId } = useParams()

  useEffect(() => {
    const getStackSurveys = async () => {
      setLoadingStackSurveys(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/productSurvey/list/${stackId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const stackSurveys = await response.body.json()
      const dateSortedSurveys = stackSurveys
        .filter(survey => survey?.productIds[0] === productId)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setStackSurveys(dateSortedSurveys)
      setLoadingStackSurveys(false)
    }

    getStackSurveys()
  }, [])

  useEffect(() => {
    getMoments()
  }, [])

  const getMoments = async () => {
    setLoadingMoments(true)
    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    const response = await get({
      apiName: 'bordercrossRest',
      path: `/surveys/productSurvey/moments/${stackId}/${productId}`,
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
      },
    }).response

    const moments = await response.body.json()
    setMoments(moments)
    setLoadingMoments(false)
  }

  const updateMoments = async moments => {
    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    return await post({
      apiName: 'bordercrossRest',
      path: `/surveys/productSurvey/moments/${stackId}/${productId}`,
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body: {
          moments,
        },
      },
    }).response
  }

  const loading = loadingStackSurveys || loadingProducts || loadingMoments

  const product = products.find(product => product.id === productId)

  const surveysWithFavourabilityData = stackSurveys.filter(
    survey => survey?.averageFavourablility?.total && survey?.subType !== 'pre-implementation' // hide pre-implmeentation surveys from trend chart as they're not comparable with post implementation
  )

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8 bg-slate-50">
      <Button
        to={`/employee-insights/product-survey/list/${stackId}`}
        variant="solid"
        color="white"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
      </Button>

      {loading ? (
        <LoadBox />
      ) : (
        <>
          <div className="flex flex-col md:flex-row gap-4 justify-between align-start mt-10">
            <div className="flex-shrink-0">
              <div className="flex flex-wrap gap-x-6 gap-y-2 items-center mt-4">
                <div className="flex flex-wrap gap-x-4 items-center">
                  <ProductImage softwareProduct={product} height="h-20" width="w-20" />
                  <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                    {product.name}
                  </h1>
                </div>

                <Button
                  variant="solid"
                  color="indigo"
                  to={`/employee-insights/product-survey-setup?stackId=${stackId}`}
                >
                  Create new survey
                </Button>
              </div>
              <p className="mt-2 text-lg leading-8 text-gray-600 max-w-2xl line-clamp-3">
                {product.description}
              </p>
            </div>
          </div>

          <div className="mt-8">
            {surveysWithFavourabilityData.length > 0 && (
              <div className="w-full hidden md:block">
                <TrendSection
                  stackSurveys={surveysWithFavourabilityData}
                  moments={moments}
                  updateMoments={updateMoments}
                  refetchMoments={getMoments}
                />
              </div>
            )}
          </div>

          <div className="mt-8">
            <h2 className="text-2xl lg:text-3xl font-semibold mt-1 text-slate-900 group-hover:underline underline-offset-2 decoration-2">
              Surveys
            </h2>
            <ul className="mt-4 space-y-8">
              {stackSurveys.map(survey => {
                const product = products.find(product => product.id === survey?.productIds?.[0])

                return (
                  <div>
                    <SurveyListItem survey={survey} product={product} />
                  </div>
                )
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default ProductSurveyListPage
