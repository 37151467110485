import React, { useState, useEffect } from 'react'
import * as htmlToImage from 'html-to-image'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer'
import SurveyResultBox from './PDF/SurveyResultBox'
import SurveyCommentsBox from './PDF/SurveyCommentsBox'

// Register fonts
Font.register({
  family: 'Inter',
  fonts: [
    { src: '/pdfFonts/Inter-Regular.ttf' },
    { src: '/pdfFonts/Inter-SemiBold.ttf', fontWeight: 'bold' },
  ],
})

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 90,
    paddingBottom: 40,
    paddingHorizontal: 30,
    fontSize: 14,
    fontFamily: 'Inter',
  },
  header: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  content: {
    marginTop: 40, // Adjust for space below the header
    fontSize: 12,
    lineHeight: 1.5,
  },
  section: {
    marginBottom: 10,
  },
  image: {
    marginTop: 20,
    width: '100%',
    height: 'auto',
  },
})

// Create Document Component
export const ExportSurveyPDF = ({ survey, product, stack, parsedHeatmapSummaries, questions }) => {
  const [heatmapImage, setHeatmapImage] = useState(null)

  // Capture the heatmap image after the component is mounted
  useEffect(() => {
    const captureImage = async () => {
      const element = document.getElementById('heatmap')

      if (element) {
        const dataUrl = await htmlToImage.toPng(element)
        setHeatmapImage(dataUrl)
      }
    }

    captureImage() // Trigger the image capture on component mount
  }, [])

  // Calculate some data from the survey object
  let completionsCount = survey?.completions?.length || 0
  let answersCount = 0
  let commentsCount = 0

  survey?.completions?.forEach(completion => {
    completion.answers.forEach(answer => {
      if (answer.comment || answer.rating) answersCount++
      if (answer.comment) commentsCount++
    })
  })

  const formattedSurveyDate = new Date(survey.createdAt)?.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: '2-digit',
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header} fixed>
          <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Bordercross Health</Text>
            <Text style={{ fontSize: 12 }}>Empower your team. Enhance your care.</Text>
          </View>
          <Image style={{ width: '60px' }} src="/images/logo-white-cropped-no-bg.png" />
        </View>

        {/* Content */}
        <View style={styles.content}>
          <View style={{ marginTop: 20, marginBottom: 40 }}>
            <Text style={{ fontWeight: 'bold', fontSize: '12px', color: 'gray' }}>
              {stack?.name} | {formattedSurveyDate}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 38 }}>
              {survey?.subType === 'pre-implementation'
                ? 'Pre-implementation'
                : 'Post-implementation'}
              : {product?.name}
            </Text>
          </View>

          <View style={{ marginTop: 20 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 6 }}>
              Survey response
            </Text>
            <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 6 }}>
              {completionsCount} surveys completed | {answersCount} questions answered |{' '}
              {commentsCount} comments received
            </Text>
          </View>

          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 22,
              marginBottom: 4,
              marginTop: 20,
            }}
          >
            Summary
          </Text>

          {/* Conditional rendering based on survey type */}
          {survey?.subType === 'pre-implementation' ? (
            <>
              <PreImplementationPoints parsedHeatmapSummaries={parsedHeatmapSummaries} />
            </>
          ) : (
            <>
              <PostImplementationPoints parsedHeatmapSummaries={parsedHeatmapSummaries} />
            </>
          )}

          {/* Render heatmap image */}
          {heatmapImage && <Image src={heatmapImage} style={{ ...styles.image }} />}

          <View break>
            <Text style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 20 }}>Results</Text>
            {questions
              ?.filter(question => question.type === 'rating')
              .map(question => {
                const answers = []
                survey?.completions?.forEach(completion =>
                  completion.answers?.map(answer => {
                    if (answer.questionId === question.id) answers.push(answer)
                  })
                )
                const ratings = answers.map(answer => answer.rating)

                return (
                  <SurveyResultBox
                    question={question.question.replace('<<softwareName>>', product.name)}
                    ratings={ratings}
                  />
                )
              })}
          </View>

          <View break>
            <Text style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 4 }}>Comments</Text>
            {questions
              ?.filter(question =>
                survey?.completions.find(completion =>
                  completion.answers.find(
                    answer => answer.comment && question.id === answer.questionId
                  )
                )
              )
              .map(question => {
                const answers = []
                survey?.completions.forEach(completion =>
                  completion.answers?.map(answer => {
                    if (answer.questionId === question.id)
                      answers.push({
                        ...answer,
                        role: completion.role,
                      })
                  })
                )
                const comments = answers
                  .filter(answer => answer.comment)
                  .map(answer => {
                    return {
                      comment: answer.comment,
                      sentiment: answer.commentSentiment,
                      role: answer.role,
                    }
                  })

                return (
                  <SurveyCommentsBox
                    question={question.question.replace('<<softwareName>>', product.name)}
                    comments={comments}
                  />
                )
              })}
          </View>
        </View>
      </Page>
    </Document>
  )
}

const PreImplementationPoints = ({ parsedHeatmapSummaries }) => (
  <>
    {parsedHeatmapSummaries?.summary?.length > 0 && (
      <View>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Overview</Text>
        {parsedHeatmapSummaries?.summary?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.benefits?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Potential benefits</Text>
        {parsedHeatmapSummaries?.benefits?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.risks?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Potential Risks</Text>
        {parsedHeatmapSummaries?.risks?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.mitigations?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Suggested mitigations</Text>
        {parsedHeatmapSummaries?.mitigations?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}
  </>
)

const PostImplementationPoints = ({ parsedHeatmapSummaries }) => (
  <>
    {parsedHeatmapSummaries?.summary?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Overview</Text>
        {parsedHeatmapSummaries?.summary?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.effectiveAspects?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>What works well</Text>
        {parsedHeatmapSummaries?.effectiveAspects?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.challenges?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Challenges</Text>
        {parsedHeatmapSummaries?.challenges?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}

    {parsedHeatmapSummaries?.userSuggestions?.length > 0 && (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 4 }}>Suggestions</Text>
        {parsedHeatmapSummaries?.userSuggestions?.map((summaryPoint, index) => (
          <Text key={index}>• {summaryPoint}</Text>
        ))}
      </View>
    )}
  </>
)
