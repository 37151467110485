import React from 'react'
import { BuildingOfficeIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { numberWithCommas } from '../../../../utilities/general'
import {
  getStackCost,
  calculateProductsDueForRenewelInNMonths,
  calculateComplianceFieldCompletion,
} from '../../../../utilities/stacks'
import PcnContractBadge from '../PcnContractBadge'
import Button from '../../../Button'
import ProductRow from './ProductRow'

const StackRow = ({
  filters,
  selectedFilters,
  stack,
  pcnProductDrawersOpen,
  togglePcnProductDrawer,
}) => {
  const cost = getStackCost(stack)
  const renewalsDueWithin3Months = calculateProductsDueForRenewelInNMonths(stack, 3)
  const renewalsDueWithin12Months = calculateProductsDueForRenewelInNMonths(stack, 12)
  const dpiaCompletion = calculateComplianceFieldCompletion(stack, 'DPIAStatus')
  const dcb0160Completion = calculateComplianceFieldCompletion(stack, 'DCB0160Status')
  const sharingAgreementStatusCompletion = calculateComplianceFieldCompletion(
    stack,
    'sharingAgreementStatus'
  )
  const pcnProductDrawerOpen = pcnProductDrawersOpen.includes(stack.id)

  let filteredProducts = stack.SoftwareProducts?.items
  if (selectedFilters.length) {
    filteredProducts = stack.SoftwareProducts?.items?.filter(product => {
      const selectedProductManagementDetail = stack.StackProductManagements?.items?.find(
        productManagement =>
          productManagement.productManagementDetailsSoftwareProductId === product.softwareProductId
      )

      return filters.every(filter => {
        const selectedFilterInCategory = selectedFilters.filter(selectedFilter =>
          filter.options.find(option => option.id === selectedFilter)
        )
        if (selectedFilterInCategory.length === 0) {
          return true
        }

        return selectedFilterInCategory.some(selectedFilter => {
          const filterType = filters.find(filter =>
            filter.options.find(filterOption => filterOption.id === selectedFilter)
          )

          if (filterType.id === 'category') {
            const matchesCategory = product?.softwareProduct?.productCategory?.id === selectedFilter
            return matchesCategory
          }
          if (filterType.id === 'tag') {
            const matchesTag = product?.softwareProduct?.productTags?.items?.some(
              tag => selectedFilter === tag.productTagId
            )
            return matchesTag
          }
          if (filterType.id === 'icb') {
            const matchesICB = selectedFilters.includes(stack?.organisation?.icbId)
            return matchesICB
          }
          if (filterType.id === 'pcn') {
            const matchesPCN = selectedFilters.includes(stack?.organisation?.pcnId)
            return matchesPCN
          }
          if (filterType.id === 'procured') {
            const procuredInternally = selectedFilter === 'internally' ? true : false
            const matchedProcuredValue =
              selectedProductManagementDetail?.procuredInternally === procuredInternally
            return matchedProcuredValue
          }

          return false
        })
      })
    })
  }

  return (
    <>
      <tr key={stack.id} className={'border-gray-200'} data-height="30">
        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3">
          <div className="flex items-center">
            <BuildingOfficeIcon className="h-12 w-12 p-2 font-bold stroke-1" />
            <Link className="hover:underline" to={`/dashboard?stackId=${stack.id}`}>
              {stack.name}
            </Link>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {isNaN(cost) ? 'Unavailable' : `£${numberWithCommas(cost.toFixed(2))}`}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          <div className="">
            <PcnContractBadge
              count={renewalsDueWithin3Months.length}
              monthsRemaining={3}
              type="badge"
            />
          </div>
          <div>
            <PcnContractBadge
              count={renewalsDueWithin12Months.length}
              monthsRemaining={12}
              type="text"
            />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {dpiaCompletion.completed}/{dpiaCompletion.total}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {dcb0160Completion.completed}/{dcb0160Completion.total}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          {sharingAgreementStatusCompletion.completed}/{sharingAgreementStatusCompletion.total}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          <Button color="indigo" variant="soft" onClick={() => togglePcnProductDrawer(stack.id)}>
            {pcnProductDrawerOpen ? 'Hide' : 'Show'} products{' '}
            {pcnProductDrawerOpen ? (
              <ChevronUpIcon className="ml-2 w-4 h-4" />
            ) : (
              <ChevronDownIcon className="ml-2 w-4 h-4" />
            )}
          </Button>
        </td>
      </tr>

      {pcnProductDrawerOpen && (
        <>
          {filteredProducts.length ? (
            <>
              {filteredProducts?.map(product => (
                <ProductRow product={product} stack={stack} />
              ))}
            </>
          ) : (
            <td className="pl-10 text-gray-500" colSpan={7}>
              No products
            </td>
          )}
        </>
      )}
    </>
  )
}

export default StackRow
