import { ResponsiveHeatMap } from '@nivo/heatmap'

import { classNames } from '../../../utilities/general'
import './ResultsHeatmap.css'

const ResultsHeatmap = ({ survey, selectedBusinessProcess }) => {
  const roles = new Set()
  survey?.completions?.forEach(completion => completion.role && roles.add(completion.role))

  const questions = selectedBusinessProcess.questions.filter(question => question.type === 'rating')

  // This is needed to avoid Tailwind tree shaking causing column styling to fail if
  // the relevant column class is created dynamically
  const heatmapGrodColumnsOptions = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
    9: 'grid-cols-9',
    10: 'grid-cols-10',
    11: 'grid-cols-11',
    12: 'grid-cols-11',
    13: 'grid-cols-13',
    14: 'grid-cols-14',
    15: 'grid-cols-15',
  }

  return (
    <div className={`grid gap-1 ${heatmapGrodColumnsOptions[questions.length + 1]} mt-2 mr-8`}>
      <div></div>
      {questions?.map(question => (
        <div className="relative h-20">
          <div className="absolute text-sm bottom-0 left-[50%] origin-left -rotate-[30deg] whitespace-nowrap mb-1 font-semibold">
            {question.shortQuestion}
          </div>
          <div className="heatmap-tick absolute text-sm -bottom-1 whitespace-nowrap border border-slate-200 h-2"></div>
        </div>
      ))}

      {[...roles]?.map(role => {
        const completionsForQuestionAndRole = new Set()
        questions?.map(question => {
          survey?.completions?.forEach(completion => {
            completion?.answers?.forEach(answer => {
              if (
                answer.businessProcessId === selectedBusinessProcess.id &&
                answer.questionId === question.id &&
                completion.role === role
              ) {
                completionsForQuestionAndRole.add(completion.id)
              }
            })
          })
        })

        return (
          <>
            <div className="h-14 flex text-sm items-center justify-end text-right mr-1 font-semibold border-b border-slate-200">
              {role} ({completionsForQuestionAndRole.size})
            </div>

            {questions?.map(question => {
              const ratingsForQuestionAndRole = []
              const answersForQuestionAndRole = []
              survey?.completions?.forEach(completion => {
                completion?.answers?.forEach(answer => {
                  if (
                    answer.businessProcessId === selectedBusinessProcess.id &&
                    answer.questionId === question.id &&
                    completion.role === role
                  ) {
                    answersForQuestionAndRole.push(answer)
                    completionsForQuestionAndRole.add(completion.id)
                  }
                })
              })

              answersForQuestionAndRole.forEach(answerForQuestionAndRole => {
                if (typeof answerForQuestionAndRole.rating === 'number') {
                  ratingsForQuestionAndRole.push(answerForQuestionAndRole.rating)
                }
              })

              if (ratingsForQuestionAndRole.length) {
                let ratingsSum = 0
                ratingsForQuestionAndRole.forEach(ratingForQuestionAndRole => {
                  ratingsSum += ratingForQuestionAndRole
                })
                const averageRating = ratingsSum / ratingsForQuestionAndRole.length

                let backgroundColour
                if (averageRating < 1.5) backgroundColour = 'bg-red-500'
                else if (averageRating < 2.5) backgroundColour = 'bg-red-300'
                else if (averageRating < 3.5) backgroundColour = 'bg-slate-100'
                else if (averageRating < 4.5) backgroundColour = 'bg-green-300'
                else backgroundColour = 'bg-green-500'

                return (
                  <div
                    className={classNames(
                      backgroundColour,
                      'h-14 flex items-center justify-center text-sm rounded overflow-hidden'
                    )}
                  >
                    <div>{parseFloat(Number(averageRating - 3).toFixed(2))}</div>
                  </div>
                )
              } else {
                return (
                  <div
                    className={
                      'bg-slate-50 h-14 flex items-center justify-center text-sm rounded overflow-hidden'
                    }
                  >
                    <div>N/A</div>
                  </div>
                )
              }
            })}
          </>
        )
      })}
    </div>
  )
}

export default ResultsHeatmap
