import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Modal from './Modal'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import SurveyQuestion from './SurveyQuestion'
import Button from './Button'

const BusinessProcessSurveyPreview = ({ modalOpen, setModalOpen, process, orgName }) => {
  const form = useForm()

  return (
    <Modal open={modalOpen} setOpen={setModalOpen}>
      <div>
        <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          Business Process Survey
        </h1>

        <div className="flex gap-2 items-center mt-2">
          <BuildingOfficeIcon className="h-8 w-8 text-gray-600 stroke-1" />
          <p className="text-xl text-gray-600">{orgName}</p>
        </div>
      </div>

      <div>
        <h3 className="mt-6 text-sm">This section is about: </h3>
        <div className="mt-2 flex gap-4 items-center">
          <div className="flex-shrink-0">
            <div className="flex h-20 w-20 rounded overflow-hidden bg-blue-500">
              <div className="w-full self-center">
                <div className="flex justify-center text-gray-400">
                  <process.icon className="h-10 w-10 text-blue-50" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-semibold text-lg">{process.name}</h2>
            <p className="text-gray-600">{process.shortDescription}</p>
          </div>
        </div>
      </div>

      {process.questions.map(question => {
        return <SurveyQuestion question={question} form={form} />
      })}

      <div className="flex justify-end">
        <Button variant="solid" color="blue" onClick={() => setModalOpen(false)}>
          Close preview
        </Button>
      </div>
    </Modal>
  )
}

export default BusinessProcessSurveyPreview
