import React from 'react'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

const ComplianceCell = ({ status, downloadingXLS = false }) => {
  return (
    <>
      {status === 'Completed' && (
        <td
          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          data-f-color="FF16A34A"
          data-f-sz="16"
        >
          <CheckCircleIcon className="h-5 w-5 text-green-600" />
          {downloadingXLS && <>✓</>}
        </td>
      )}
      {status === 'Not completed' && (
        <td
          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          data-f-color="FFDC2626"
          data-f-sz="16"
        >
          <XCircleIcon className="h-5 w-5 text-red-600" />
          {downloadingXLS && <>x</>}
        </td>
      )}
      {status !== 'Completed' && status !== 'Not completed' && (
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <>-</>
        </td>
      )}
    </>
  )
}

export default ComplianceCell
