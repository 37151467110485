import { useRef, useState } from 'react'
import { post } from 'aws-amplify/api'
import { useForm } from 'react-hook-form'
import { fetchAuthSession } from 'aws-amplify/auth'

import ProductForm from './ProductForm'
import ProductImage from './ProductImage'

export default function EditProductModal({
  currentProduct,
  stack,
  getStack,
  productManagementDetail,
  setOpen,
}) {
  const cancelButtonRef = useRef(null)
  const [updatingLoading, setUpdatingLoading] = useState(false)
  const { control, register, handleSubmit, watch } = useForm({
    defaultValues: {
      ...productManagementDetail,
      procuredInternally:
        productManagementDetail?.procuredInternally === true
          ? 'internal'
          : productManagementDetail?.procuredInternally === false
            ? 'external'
            : '',
    },
  })

  const onSubmit = async data => {
    setUpdatingLoading(true)

    let procuredInternally = null
    if (data?.procuredInternally === 'internal') procuredInternally = true
    if (data?.procuredInternally === 'external') procuredInternally = false

    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    await post({
      apiName: 'bordercrossRest',
      path: '/stacks/productManagementDetails/update',
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body: {
          input: {
            id: productManagementDetail?.id,
            softwarestackID: productManagementDetail.softwarestackID,
            DCB0160Status: data.DCB0160Status,
            DPIAStatus: data.DPIAStatus,
            sharingAgreementStatus: data.sharingAgreementStatus,
            contractStartDate: data.contractStartDate || null,
            contractEndDate: data.contractEndDate || null,
            price: data.price || null,
            pricingPlan: data.pricingPlan,
            procuredInternally: procuredInternally,
            notes: data.notes,
          },
        },
      },
    }).response

    getStack(stack.id, false)
    setUpdatingLoading(false)
    setOpen(false)
  }

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll">
        <div className="flex gap-2 items-center">
          <ProductImage softwareProduct={currentProduct} height="h-16" width="w-16" />
          <h2 className="text-xl sm:text-4xl font-bold">Edit {currentProduct.name}</h2>
        </div>

        <h3 className="sm:text-xl font-bold text-gray-900 mt-6">Edit product details</h3>
        <ProductForm
          control={control}
          product={currentProduct}
          register={register}
          stack={stack}
          watch={watch}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          disabled={updatingLoading ? true : false}
          type="button"
          className={
            'inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-400 disabled:hover:bg-blue-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
          }
          onClick={handleSubmit(data => onSubmit(data))}
        >
          {updatingLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>

        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  )
}
