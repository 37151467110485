import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const SelectBusinessProcess = ({
  businessProcesses,
  selectedBusinessProcess,
  setSelectedBusinessProcess,
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {businessProcesses?.map(businessProcess => {
        const isSelected = businessProcess.id === selectedBusinessProcess?.id

        if (isSelected) {
          return (
            <button
              type="button"
              className="flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setSelectedBusinessProcess(businessProcess)}
            >
              <businessProcess.icon className="h-8 w-8" />
              <div className="text-left">
                <div>{businessProcess.name}</div>
              </div>
              <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          )
        } else {
          return (
            <button
              type="button"
              className="flex items-center gap-2 rounded-md bg-white px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setSelectedBusinessProcess(businessProcess)}
            >
              <businessProcess.icon className="h-8 w-8" />
              <div className="text-left">
                <div>{businessProcess.name}</div>
              </div>
            </button>
          )
        }
      })}
    </div>
  )
}

export default SelectBusinessProcess
