import { ArrowRightIcon } from '@heroicons/react/24/outline'
import LoadIcon from '../../LoadingIcon'

const NextButton = ({
  disabled = false,
  onClick,
  loading = false,
  text = 'Next',
  type = 'button',
}) => (
  <button
    disabled={disabled}
    className={
      'inline-flex items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-3 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-400 disabled:hover:bg-indigo-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2'
    }
    onClick={onClick}
    type={type}
  >
    <div className="flex items-center gap-2">
      {loading && <LoadIcon textColor="text-white" />}
      {text} <ArrowRightIcon className="h-5 w-5 ml-1 " />
    </div>
  </button>
)

export default NextButton
