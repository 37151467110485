import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import ProductPanelHeader from './ProductPanelHeader'
import ProductPanelContents from './ProductPanelContents'

const ProductPanel = ({
  isReadAccess,
  currentProduct,
  setCurrentProduct,
  productManagementDetail,
  setEditProductModalOpen,
  setRemoveProductDialogOpen,
}) => {
  const isLargeViewport = window.innerWidth > 1024

  const mobile = (
    <Transition.Root show={!!currentProduct} as={Fragment}>
      <Dialog as="div" className="relative z-10 lg:hidden" onClose={() => setCurrentProduct()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                //   leave="transform transition ease-in-out duration-500 sm:duration-700"
                //   leaveFrom="translate-x-0"
                //   leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div></div>
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          <ProductPanelHeader
                            currentProduct={currentProduct}
                            setEditProductModalOpen={setEditProductModalOpen}
                            setRemoveProductDialogOpen={setRemoveProductDialogOpen}
                            isReadAccess={isReadAccess}
                            hideButtons={true}
                          />
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setCurrentProduct()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <ProductPanelContents productManagementDetail={productManagementDetail} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

  const desktop = (
    <>
      {currentProduct && (
        <div className="p-4 bg-white border border-gray-300 rounded-md sticky max-h-screen overflow-y-auto top-0">
          <div className="flex justify-between mb-8 gap-2">
            <div></div>
            <ProductPanelHeader
              currentProduct={currentProduct}
              setEditProductModalOpen={setEditProductModalOpen}
              setRemoveProductDialogOpen={setRemoveProductDialogOpen}
              isReadAccess={isReadAccess}
              hideButtons={false}
            />
            <div>
              <button
                className="bg-gray-100 hover:bg-gray-200 rounded-full p-1 mt-1"
                onClick={() => setCurrentProduct()}
              >
                <XMarkIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </div>

          <ProductPanelContents productManagementDetail={productManagementDetail} />
        </div>
      )}
    </>
  )

  return (
    <>
      {isLargeViewport ? (
        <div className="hidden lg:block">{desktop}</div>
      ) : (
        <div className="lg:hidden">{mobile}</div>
      )}
    </>
  )
}

export default ProductPanel
