import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

const RecommendedProductStepper = ({ value }) => {
  let colour
  if (value >= 90) {
    colour = '#16a34a'
  } else if (value >= 80) {
    colour = '#22c55e'
  } else if (value >= 70) {
    colour = '#4ade80'
  } else if (value >= 60) {
    colour = '#fbbf24'
  } else if (value >= 50) {
    colour = '#f59e0b'
  } else if (value >= 40) {
    colour = '#d97706'
  } else if (value >= 30) {
    colour = '#fbbf24'
  } else if (value >= 20) {
    colour = '#ef4444'
  } else if (value >= 10) {
    colour = '#dc2626'
  } else if (value >= 0) {
    colour = '#b91c1c'
  }

  return (
    <div className="flex flex-col items-center">
      <div className="text-xs font-semibold" style={{ color: colour }}>
        Recommended by
      </div>
      <div className="w-16 mt-1">
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) => {
            const percentage = isVisible ? value : 0
            return (
              <CircularProgressbar
                value={percentage}
                text={`${value?.toFixed(0)}%`}
                styles={{
                  ...buildStyles({
                    pathColor: colour,
                    textSize: 24,
                    pathTransitionDuration: 1,
                  }),
                  text: {
                    fontWeight: 'bold',
                    fill: colour,
                    size: '30px',
                  },
                }}
              />
            )
          }}
        </VisibilitySensor>
      </div>
    </div>
  )
}

export default RecommendedProductStepper
