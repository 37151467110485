import React, { useState } from 'react'

import StackRow from './StackRow'
import Button from '../../../Button'
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline'

const PcnTable = ({
  pcnProductDrawersOpen,
  setPcnProductDrawersOpen,
  filters,
  selectedFilters,
  stacks,
}) => {
  const togglePcnProductDrawer = id => {
    if (pcnProductDrawersOpen.includes(id)) {
      setPcnProductDrawersOpen(pcnProductDrawersOpen.filter(openId => openId !== id))
    } else {
      setPcnProductDrawersOpen([...pcnProductDrawersOpen, id])
    }
  }

  return (
    <div className="shadow rounded-md overflow-hidden">
      <table id="table" className="min-w-full" data-cols-width="40,20,20,20,12,12,12">
        <thead className="bg-indigo-100">
          <tr className="align-top" data-height="40">
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Organisation
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              <div className="mb-2">Yearly Cost</div>
              {/* <PriceSwitch
                perPatientPricing={perPatientPricing}
                setPerPatientPricing={setPerPatientPricing}
              /> */}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Contract Renewals Due
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              DPIA
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Clinical risk safety
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              Data sharing agreement
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              data-f-bold
              data-fill-color="FFE5E7EB"
            >
              {pcnProductDrawersOpen.length > 0 && (
                <Button color="indigo" variant="soft" onClick={() => setPcnProductDrawersOpen([])}>
                  Hide all <ChevronDoubleUpIcon className="h-4 w-4 ml-4" />
                </Button>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {stacks.map(stack => (
            <StackRow
              filters={filters}
              selectedFilters={selectedFilters}
              stack={stack}
              pcnProductDrawersOpen={pcnProductDrawersOpen}
              togglePcnProductDrawer={togglePcnProductDrawer}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PcnTable
