import React, { useState } from 'react'
import Datepicker from 'react-tailwindcss-datepicker'
import { format } from 'date-fns'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

import Modal from '../../Modal'
import Button from '../../Button'
import LoadingIcon from '../../LoadingIcon'

const MomentsModal = ({ setOpen, moments, updateMoments, refetchMoments }) => {
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      moments: moments?.map(moment => {
        return {
          description: moment.description,
          date: moment.date && {
            startDate: new Date(moment.date),
            endDate: new Date(moment.date),
          },
        }
      }),
    },
    shouldUnregister: true,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'moments',
  })

  const onSubmit = async data => {
    setLoadingUpdate(true)
    const moments = data.moments
      .map(moment => {
        let formattedDate

        try {
          formattedDate = format(moment?.date?.startDate, 'yyyy-MM-dd')
        } catch (e) {
          return
        }

        return { date: formattedDate, description: moment?.description }
      })
      .filter(moment => moment)

    await updateMoments(moments)
    await refetchMoments()
    setLoadingUpdate(false)
    setOpen(false)
  }

  return (
    <>
      <div>
        <h2 className="text-xl sm:text-4xl font-bold">Edit events</h2>
        <p className="mt-2">
          We'll use your recorded events to annotate your trend chart so you can see how these may
          have impacted your organisation over time.
        </p>
        <p className="mt-2">Tip: Keep them short and include only few key events.</p>
      </div>

      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          {fields.map((field, index) => (
            <MomentField
              field={field}
              index={index}
              remove={remove}
              register={register}
              control={control}
            />
          ))}
        </div>

        <div className="mt-4">
          <Button
            className="flex gap-1 w-full max-w-sm"
            variant="soft"
            color="indigo"
            onClick={append}
            type="button"
          >
            <PlusIcon className="h-5 w-5" />
            Add
          </Button>
        </div>

        <div className="mt-16 flex gap-2 itmes-center">
          <Button
            className="flex gap-1 w-full"
            variant="soft"
            color="gray"
            onClick={() => setOpen(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button className="flex gap-1 w-full" variant="solid" color="indigo" type="submit">
            Save
            {loadingUpdate && (
              <span className="ml-2">
                <LoadingIcon height="h-4" width="w-4" textColor="text-white" />
              </span>
            )}
          </Button>
        </div>
      </form>
    </>
  )
}

const MomentField = ({ field, index, remove, register, control }) => (
  <div className="flex gap-2" key={field.id}>
    <label htmlFor="search" className="block text-md leading-6 text-gray-900">
      Description
      <input
        type="text"
        name="search"
        maxLength={'18'}
        id="search"
        {...register(`moments.${index}.description`)}
        className="mt-1 block w-full rounded-md border-0 px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
      />
    </label>

    <label htmlFor="search" className="block text-md leading-6 text-gray-900">
      Date
      <div>
        <Controller
          name={`moments.${index}.date`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Datepicker
                primaryColor={'indigo'}
                value={value}
                onChange={onChange}
                displayFormat="DD/MM/YYYY"
                containerClassName="mt-1 relative"
                inputClassName="rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 border-none"
                asSingle={true}
                useRange={false}
              />
            )
          }}
        />
      </div>
    </label>

    <div className="mt-7">
      <Button
        className="flex gap-1"
        variant="soft"
        color="red"
        onClick={() => remove(index)}
        type="button"
      >
        <MinusIcon className="h-5 w-5" />
        Remove
      </Button>
    </div>
  </div>
)

export default MomentsModal
