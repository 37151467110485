import React, { useState, useEffect } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'

const ProductImage = ({
  softwareProduct,
  height = 'h-24',
  width = 'w-24',
  hideBorder = false,
  grayscale,
}) => {
  const [imageFailed, setImageFailed] = useState(false)

  useEffect(() => {
    setImageFailed(false)
  }, [softwareProduct])

  return (
    <div
      className={`flex ${height} ${width} ${
        hideBorder ? '' : 'border border-gray-300'
      } ${grayscale ? 'grayscale' : ''} rounded overflow-hidden bg-white`}
    >
      <div className="w-full self-center">
        {softwareProduct?.imageUrl && !imageFailed ? (
          <img
            onError={() => setImageFailed(true)}
            className="mx-auto"
            src={`${process.env.PUBLIC_URL}/images/product-logos/${softwareProduct?.imageUrl}`}
            alt={`${softwareProduct?.name} Logo`}
          />
        ) : (
          <div className="flex justify-center text-gray-400">
            <PhotoIcon className={`${height} ${width} p-[15%]`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductImage
