import React from 'react'

const SurveySummaryOutput = ({ survey, product, surveyQuestions }) => {
  const productCommentSummaries = survey.commentSummaries.find(
    commentSummary => commentSummary.productId === product.id
  )

  let parsedSummaries
  try {
    parsedSummaries = JSON.parse(productCommentSummaries?.summaryText)
  } catch (e) {}

  const roles = new Set()
  survey?.completions?.forEach(completion => completion.role && roles.add(completion.role))

  const heatmapSurveyQuestions = surveyQuestions?.filter(question => question.type === 'rating')

  return (
    <>
      {parsedSummaries?.summary?.length > 0 && (
        <div>
          <h4 className="font-semibold">Overview</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.summary?.map(summaryPoint => (
              <li className="mt-2">{summaryPoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.benefits?.length > 0 && (
        <div>
          <h4 className="font-semibold">Potential benefits</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.benefits?.map(effectivePoint => (
              <li className="mt-2">{effectivePoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.risks?.length > 0 && (
        <div>
          <h4 className="font-semibold">Potential Risks</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.risks?.map(challengePoint => (
              <li className="mt-2">{challengePoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.mitigations?.length > 0 && (
        <div>
          <h4 className="font-semibold">Suggested mitigations</h4>

          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.mitigations?.map(suggestionPoint => (
              <li className="mt-2">{suggestionPoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}

      <h3>Survey Responses Matrix</h3>
      <p>
        Key: <strong>2 = Favourable, 0 = Neutral, -2 = Unfavourable</strong>
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            {heatmapSurveyQuestions?.map(productQuestion => (
              <th>{productQuestion.shortQuestion}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {[...roles]?.map(role => {
            const completionsForQuestionAndRole = new Set()
            surveyQuestions?.map(productQuestion => {
              survey?.completions?.forEach(completion => {
                completion?.answers?.forEach(answer => {
                  if (
                    answer.productId === product.id &&
                    answer.questionId === productQuestion.id &&
                    completion.role === role
                  ) {
                    completionsForQuestionAndRole.add(completion.id)
                  }
                })
              })
            })

            return (
              <tr>
                <td>
                  <strong>{role}</strong> ({completionsForQuestionAndRole.size})
                </td>

                {heatmapSurveyQuestions?.map(productQuestion => {
                  const ratingsForQuestionAndRole = []
                  const answersForQuestionAndRole = []
                  survey?.completions?.forEach(completion => {
                    completion?.answers?.forEach(answer => {
                      if (
                        answer.productId === product.id &&
                        answer.questionId === productQuestion.id &&
                        completion.role === role
                      ) {
                        answersForQuestionAndRole.push(answer)
                        completionsForQuestionAndRole.add(completion.id)
                      }
                    })
                  })

                  answersForQuestionAndRole.forEach(answerForQuestionAndRole => {
                    if (typeof answerForQuestionAndRole.rating === 'number') {
                      ratingsForQuestionAndRole.push(answerForQuestionAndRole.rating)
                    }
                  })

                  if (ratingsForQuestionAndRole.length) {
                    let ratingsSum = 0
                    ratingsForQuestionAndRole.forEach(ratingForQuestionAndRole => {
                      ratingsSum += ratingForQuestionAndRole
                    })
                    const averageRating = ratingsSum / ratingsForQuestionAndRole.length

                    return (
                      <td>
                        <div>{parseFloat(Number(averageRating - 3).toFixed(2))}</div>
                      </td>
                    )
                  } else {
                    return (
                      <td>
                        <div>N/A</div>
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
    </>
  )
}

export default SurveySummaryOutput
