import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DesktopFilters = ({ filters, handleFilterChange, selectedFilters }) => {
  return (
    <div className="hidden lg:block">
      <form className="space-y-10 divide-y divide-gray-200">
        {filters.map((section, sectionIdx) => (
          <div key={section.name} className={sectionIdx === 0 ? null : 'pt-10'}>
            <fieldset>
              <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
              <div className="space-y-3 pt-6">
                {section.options.map((option, optionIdx) => (
                  <div key={option.id} className="flex items-center">
                    <input
                      id={`${section.id}-${optionIdx}`}
                      name={`${section.id}[]`}
                      defaultValue={option.id}
                      type="checkbox"
                      onChange={handleFilterChange}
                      defaultChecked={selectedFilters.includes(option.id)}
                      className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label
                      htmlFor={`${section.id}-${optionIdx}`}
                      className="ml-3 text-sm text-gray-600"
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        ))}
      </form>
    </div>
  )
}

export default DesktopFilters
