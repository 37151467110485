import React from 'react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import { getCQCRatingText, getCQCRatingDescription } from '../../utilities/phe'
import { RatingArrow } from './'

const CQCRating = ({ pheDetails }) => {
  if (pheDetails?.populationSummary?.CQCRatingID && pheDetails?.populationSummary?.CQCRatingID < 0)
    return

  return (
    <div className="w-full md:w-80">
      <div className="grid grid-cols-4">
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.CQCRatingID === 1 && <RatingArrow bg="bg-red-500" />}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.CQCRatingID === 2 && <RatingArrow bg="bg-amber-400" />}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.CQCRatingID === 3 && <RatingArrow bg="bg-green-400" />}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.CQCRatingID === 4 && <RatingArrow bg="bg-green-600" />}
        </div>
        <div className="bg-red-500 h-4"></div>
        <div className="bg-amber-400 h-4"></div>
        <div className="bg-green-400 h-4"></div>
        <div className="bg-green-600 h-4"></div>
      </div>
      <div className="flex justify-between mt-1">
        <div className="text-gray-500 text-xs">Inadequate</div>
        <div className="text-gray-500 text-xs text-right">Outstanding</div>
      </div>
      <div className="text-center">
        CQC Rating:{' '}
        <span className="font-bold">
          {getCQCRatingText(pheDetails?.populationSummary?.CQCRatingID)}
        </span>
      </div>
      <div className="text-center text-gray-500 text-sm leading-4">
        {getCQCRatingDescription(pheDetails?.populationSummary?.CQCRatingID)}
      </div>

      <div className="hidden sm:flex gap-1 justify-center text-sm mt-1 text-gray-500">
        Report published{' '}
        {new Date(pheDetails?.populationSummary?.CQCRatingReportDate).toLocaleDateString()} |{' '}
        <a
          className="flex gap-1 items-center underline hover:text-gray-800"
          href={pheDetails?.populationSummary?.CQCRatingReportLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          View report <ArrowRightIcon className="h-3 w-3 mt-1" />
        </a>
      </div>
    </div>
  )
}

export default CQCRating
