import React from 'react'
import { getTeamDetails } from '../../../utilities/general'

const UserList = ({ users, stacks }) => {
  return (
    <>
      <div>
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Users</h3>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {users?.map(user => {
              const userStacks = stacks.filter(stack => stack.owner === user.id)
              let units = 0
              return (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    {user.givenName} {user.familyName}
                    <div className="text-gray-500">{user.email}</div>
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <ul>
                      {userStacks.map(userStack => {
                        const teamDetails = getTeamDetails(userStack.team)
                        const teamSize = teamDetails?.length || 1

                        units = units + teamSize
                        return (
                          <>
                            {userStack.organisation && (
                              <li>
                                {userStack.organisation.name} ({teamSize} users)
                              </li>
                            )}
                          </>
                        )
                      })}
                    </ul>
                    <p className="font-bold">Total units: {units}</p>
                  </dd>
                </div>
              )
            })}
          </dl>
        </div>
      </div>
    </>
  )
}

export default UserList
