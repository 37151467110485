import React from 'react'

const IntroductionOutput = ({ survey, product, stack }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  const practiceAndProductSummaryOutput = summary?.practiceAndProductSummaryOutput

  return (
    <>
      <div>
        <h2 className="font-semibold">Introduction</h2>
        <p>
          This document outlines the implementation of the {product.name} within {stack.name}. The
          purpose of this implementation is to ensure that the system is deployed, utilised,
          modified, or decommissioned in a way that supports patient safety, enhances clinical
          workflows, and aligns with organisational standards.
        </p>
        <div dangerouslySetInnerHTML={{ __html: practiceAndProductSummaryOutput }}></div>
      </div>
      <br />
    </>
  )
}

export default IntroductionOutput
