import CountUp from 'react-countup'

import { classNames } from '../utilities/general'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'

const SurveyAnswerComment = ({ question, comments }) => {
  const positiveCommentsCount = comments.filter(comment => comment.sentiment === 'POSITIVE').length
  const negativeCommentsCount = comments.filter(comment => comment.sentiment === 'NEGATIVE').length

  let sentimentColours = {
    border: {
      POSITIVE: 'border-green-400',
      NEGATIVE: 'border-red-400',
      NEUTRAL: 'border-slate-400',
      MIXED: 'border-slate-400',
    },
    text: {
      POSITIVE: 'text-green-600',
      NEGATIVE: 'text-red-600',
      NEUTRAL: 'text-slate-600',
      MIXED: 'text-slate-600',
    },
    background: {
      POSITIVE: 'bg-green-50',
      NEGATIVE: 'bg-red-50',
      NEUTRAL: 'bg-slate-100',
      MIXED: 'bg-slate-100',
    },
  }

  const sentimentOrder = ['POSITIVE', 'NEUTRAL', 'MIXED', 'NEGATIVE']

  const sortedComments = comments.sort((a, b) => {
    return sentimentOrder.indexOf(a.sentiment) - sentimentOrder.indexOf(b.sentiment)
  })

  return (
    <div className="bg-slate-50 rounded-lg">
      <div className="flex justify-between items-center gap-4 border-b border-slate-200">
        <div className="p-6">
          <h3 className="text-lg font-semibold tracking-tight text-slate-900">{question}</h3>
        </div>
        {positiveCommentsCount >= negativeCommentsCount ? (
          <div className="flex flex-col items-center font-semibold border-l border-slate-200 min-w-[8rem] p-6">
            <div className="text-2xl">
              <CountUp end={percentage(positiveCommentsCount, comments.length)} duration={3} />%
            </div>
            <div className="text-green-600">Positive</div>
          </div>
        ) : (
          <div className="flex flex-col items-center font-semibold border-l border-slate-200 min-w-[8rem] p-6">
            <div className="text-2xl">
              <CountUp end={percentage(negativeCommentsCount, comments.length)} duration={3} />%
            </div>
            <div className="text-red-600">Negative</div>
          </div>
        )}
      </div>

      <div className="p-6">
        {sortedComments.map(comment => {
          return (
            <div
              className={classNames(
                sentimentColours.border[comment.sentiment],
                sentimentColours.background[comment.sentiment],
                'flex gap-4 items-center justify-between border-l-[6px] mt-2 rounded overflow-hidden'
              )}
            >
              <div className="flex gap-2 py-2 ml-4">
                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mt-0.5 text-slate-500 flex-shrink-0" />
                <div>
                  <p className="leading-5">{comment.comment}</p>
                  <p className="text-sm text-slate-600 mt-1">{comment.role}</p>
                </div>
              </div>
              <p
                className={classNames(
                  sentimentColours.text[comment.sentiment],
                  'mr-4 text-xs text-right font-semibold min-w-[4rem]'
                )}
              >
                {comment.sentiment}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const percentage = (value, total) => {
  return (value / total) * 100
}

export default SurveyAnswerComment
