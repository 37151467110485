import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import { BuildingOfficeIcon, UserIcon } from '@heroicons/react/24/outline'
import { generateClient } from 'aws-amplify/api'

import {
  createOrganisation,
  createSoftwareStack,
  createOrganisationStats,
} from '../../graphql/mutations-static'
import StackTypeSelection from '../StackTypeSelection'
import OrganisationForm from '../OrganisationForm'
import {
  fetchOrganisationDetails,
  fetchLinkedOrganizations,
  getODSTelephone,
  getIcb,
  getPcn,
  isPcn,
  isIcb,
  isPractice,
  fetchOrganisationsWithinPcn,
  fetchOrganisationsWithinIcb,
  getNHSRegion,
  titleCaseNHS,
} from '../../utilities/ods'
import { fetchPheData } from '../../utilities/phe'

const client = generateClient()

const stackTypes = [
  {
    id: 1,
    title: 'Organisation',
    description: 'Map out the technology your organisation uses',
    icon: <BuildingOfficeIcon className="h-10 w-10 text-gray-500 stroke-1" />,
    features: [
      'Build & manage your technology',
      'Organisation insights',
      'NHS & Public Health England data',
    ],
    price: '7.99',
  },
  // {
  //   id: 2,
  //   title: "Personal",
  //   description: "Test out a stack creation for personal use",
  //   icon:  <UserIcon className="h-10 w-10 text-gray-500 stroke-1" />,
  //   features: [
  //     "Build & manage stack"
  //   ]
  // },
]

const StackTypePage = ({ cognitoUser, cognitoUserAttributes }) => {
  const navigate = useNavigate()
  const [selectedStackType, setSelectedStackType] = useState(stackTypes[0])
  const [stackNameInput, setStackNameInput] = useState('')
  const [loadingSubmission, setLoadingSubmission] = useState(false)

  const createOrganisationAndStack = async data => {
    setLoadingSubmission(true)

    const organisation = await fetchOrganisationDetails(data.odsId)
    const relationships = await fetchLinkedOrganizations(data.odsId)
    // const icb = isIcb(organisation) ? organisation : getIcb(relationships);
    // const pcn = isPcn(organisation) ? organisation : getPcn(relationships);

    let organisationsToAdd = []
    if (isPcn(organisation))
      organisationsToAdd.push(...(await fetchOrganisationsWithinPcn(organisation, relationships)))
    if (isIcb(organisation))
      organisationsToAdd.push(...(await fetchOrganisationsWithinIcb(data.odsId, relationships)))
    if (isPractice(organisation)) {
      organisationsToAdd.push({
        ...organisation,
        icb: getIcb(relationships),
        pcn: getPcn(relationships),
        nhsRegion: getNHSRegion(relationships),
      })
    }

    let lastCreatedStack

    const batchSize = 10
    const chunks = []
    for (let i = 0; i < organisationsToAdd.length; i += batchSize) {
      chunks.push(organisationsToAdd.slice(i, i + batchSize))
    }
    for (const chunk of chunks) {
      await Promise.all(
        chunk.map(async organisationToAdd => {
          const organisationName = titleCaseNHS(organisationToAdd.Name)

          const orgStats = await getOrgStats(organisationToAdd.OrgId?.extension)
          let createdOrgStats
          if (orgStats) {
            createdOrgStats = await client.graphql({
              query: createOrganisationStats,
              variables: {
                input: {
                  phePopulation: JSON.stringify(JSON.stringify(orgStats.populationSummary)), // double stringify because that's what happens in scripts when uploading direct to the db
                  phePopulationSummary: JSON.stringify(JSON.stringify(orgStats.population)),
                  owner: cognitoUser.userId,
                },
              },
              authMode: 'userPool',
            })
          }
          const createdOrgStatsId = createdOrgStats.data.createOrganisationStats.id

          const odsDetails = {
            name: organisationName,
            addressLine1: organisationToAdd.GeoLoc.Location.AddrLn1,
            town: organisationToAdd.GeoLoc.Location.Town,
            county: organisationToAdd.GeoLoc.Location.County,
            postcode: organisationToAdd.GeoLoc.Location.PostCode,
            country: organisationToAdd.GeoLoc.Location.Country,
            icbId: organisationToAdd?.icb?.OrgId?.extension,
            icbName: organisationToAdd?.icb?.Name,
            pcnId: organisationToAdd?.pcn?.OrgId?.extension,
            pcnName: organisationToAdd?.pcn?.Name,
            telephone: getODSTelephone(organisationToAdd),
            role: 'RO76',
            nhsRegionId: organisationToAdd?.nhsRegion?.OrgId?.extension,
            nhsRegionName: organisationToAdd?.nhsRegion?.Name,
            odsId: organisationToAdd.OrgId?.extension,
          }

          const createdOrganisation = await client.graphql({
            query: createOrganisation,
            variables: {
              input: {
                ...data,
                ...odsDetails,
                ...(data.adminPrivate && {
                  adminPrivate: data.adminPrivate === 'private' ? true : false,
                }),
                ...(orgStats && {
                  organisationOrganisationStatsId: createdOrgStatsId,
                }),
              },
            },
            authMode: 'userPool',
          })
          const organisationID = createdOrganisation.data.createOrganisation.id

          const createdStack = await client.graphql({
            query: createSoftwareStack,
            variables: {
              input: {
                owner: cognitoUser.userId,
                administrator: [],
                readAccess: [],
                team: [`${cognitoUser.userId}:owner:${cognitoUserAttributes.email}`],
                icbId: organisationToAdd?.icb?.OrgId?.extension,
                pcnId: organisationToAdd?.pcn?.OrgId?.extension,
                nhsRegionId: organisationToAdd?.nhsRegion?.OrgId?.extension,
                isPersonal: false,
                name: organisationName,
                organisationID,
              },
            },
            authMode: 'userPool',
          })

          lastCreatedStack = createdStack
        })
      )
    }

    navigate(`/employee-insights?stackId=${lastCreatedStack.data.createSoftwareStack.id}`)

    setLoadingSubmission(false)
  }

  // if this funciton is updated, also update the one in scripts/org-stats-updater
  // The only difference is this one uses the "await fetchPheData" function to bypass cors
  // by routing the request through our backend
  const getOrgStats = async odsId => {
    try {
      const populationSummaryUrl = `https://fingertips.phe.org.uk/api/quinary_population_summary?area_code=${odsId}&area_type_id=7`
      const populationUrl = `https://fingertips.phe.org.uk/api/quinary_population?area_code=${odsId}&area_type_id=7`

      let [populationSummaryResponse, populationResponse] = await Promise.all([
        await fetchPheData(populationSummaryUrl),
        await fetchPheData(populationUrl),
      ])

      return {
        populationSummary: populationSummaryResponse,
        population: populationResponse,
      }
    } catch {}
  }

  const createPersonalStack = async e => {
    e.preventDefault()
    const createdStack = await client.graphql({
      query: createSoftwareStack,
      variables: {
        input: {
          name: stackNameInput,
          isPersonal: true,
          owner: cognitoUser.userId,
          administrator: [],
          readAccess: [],
        },
      },
      authMode: 'userPool',
    })
    navigate(`/dashboard?stackId=${createdStack.data.createSoftwareStack.id}`)
  }

  const onSubmit = data => {
    createOrganisationAndStack(data)
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <Link
        to="/dashboard"
        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back to Dashboard
      </Link>
      <h1 className="mt-8 text-4xl font-bold tracking-tight text-gray-900">
        Add your organisation
      </h1>
      <div className="my-8">
        <StackTypeSelection
          selectedStackType={selectedStackType}
          setSelectedStackType={setSelectedStackType}
          stackTypes={stackTypes}
        />
      </div>
      {selectedStackType.title === 'Organisation' ? (
        <>
          <h2 className="font-bold text-xl">Your organisation</h2>
          <OrganisationForm
            onSubmit={onSubmit}
            isSiteAdmin={cognitoUserAttributes?.isSiteAdmin}
            loadingSubmission={loadingSubmission}
          />
        </>
      ) : (
        <div className="my-8">
          <h2 className="font-bold text-xl">Your personal stack</h2>
          <form className="lg:w-2/3" onSubmit={createPersonalStack}>
            <div className="my-6">
              <label
                htmlFor="stackName"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                Stack Name *
              </label>
              <input
                name="stackName"
                id="stackName"
                onChange={e => setStackNameInput(e.target.value)}
                value={stackNameInput}
                className="mt-2 block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6 sm:text-base"
                placeholder="Stack name"
                required
              />
            </div>
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Next <ArrowRightIcon className="h-4 w-4 ml-1" />
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default StackTypePage
