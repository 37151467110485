import React from 'react'
import { classNames } from '../utilities/general'

const SurveyComment = ({ questionId, form, required, hasError }) => {
  return (
    <div>
      <label
        htmlFor="comment"
        className={classNames(
          hasError ? 'text-red-600' : 'text-gray-900',
          'block text-sm font-medium leading-6'
        )}
      >
        Add your comment
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name="comment"
          id={`comment-${questionId}`}
          {...form.register(`comment-${questionId}`, { required })}
          className={classNames(
            hasError && 'ring-2 ring-red-600/20',
            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6'
          )}
        />
      </div>
    </div>
  )
}

export default SurveyComment
