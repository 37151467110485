import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Controller } from 'react-hook-form'

import { classNames } from '../utilities/general'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const ProductForm = ({ product, stack, register, watch, control }) => {
  const [enabled, setEnabled] = useState(false)
  const contractStartDate = watch('contractStartDate')
  const contractEndDate = watch('contractEndDate')

  // Fixes bug with radio buttons, see:
  // https://stackoverflow.com/questions/76634977/react-hook-form-not-able-to-click-radio-button-for-the-first-time-getting-null
  // https://github.com/react-hook-form/react-hook-form/issues/4604
  const customRegister = (name, options) => {
    const { ref, ...field } = register(name, options)

    const customRef = (...args) => {
      setTimeout(() => ref(...args))
    }

    return { ...field, ref: customRef }
  }

  const completionOptions = [
    { name: 'Completed' },
    { name: 'Not completed' },
    { name: 'Not required' },
  ]

  return (
    <form onSubmit={e => e.preventDefault}>
      <div className="my-8">
        <label htmlFor="price" className="block text-md font-medium leading-6 text-gray-900">
          Product Price
        </label>
        <div className="mt-2">
          <div className="flex items-center gap-2">
            <span className="text-lg">£</span>
            <div className="grid grid-cols-2 gap-2">
              <input
                {...register('price')}
                type="number"
                name="price"
                id="price"
                min="0"
                step=".01"
                className="block w-full max-w-xs rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
              />
              <select
                id="pricingPlan"
                name="pricingPlan"
                className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-md sm:leading-6"
                defaultValue="MONTHLY"
                {...register('pricingPlan')}
              >
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
                {!!(stack?.organisation?.odsId || !stack) && (
                  <option value="YEARLY_PER_PATIENT">Yearly per patient</option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="my-8">
        <div className="my-8">
          <Controller
            control={control}
            name="procuredInternally"
            render={({ field: { onChange, value } }) => (
              <div>
                <RadioGroup value={value} onChange={onChange} className="mt-2">
                  <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
                    Procured by practice or externally?
                  </RadioGroup.Label>
                  <div className="mt-2 grid grid-cols-2 gap-3 w-3/4">
                    <RadioGroup.Option
                      value="internal"
                      className={({ active, checked }) =>
                        classNames(
                          active ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                          checked
                            ? 'bg-blue-600 text-white hover:bg-blue-500'
                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                          'flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1'
                        )
                      }
                    >
                      <RadioGroup.Label className="flex gap-1 items-center" as="span">
                        {'internal' === value && <CheckCircleIcon className="h-5 w-5" />}
                        Procured by practice
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                    <RadioGroup.Option
                      value="external"
                      className={({ active, checked }) =>
                        classNames(
                          active ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                          checked
                            ? 'bg-blue-600 text-white hover:bg-blue-500'
                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                          'flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1'
                        )
                      }
                    >
                      <RadioGroup.Label className="flex gap-1 items-center" as="span">
                        {'external' === value && <CheckCircleIcon className="h-5 w-5" />}
                        Procured externally
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
              </div>
            )}
          />
        </div>
      </div>

      <div className="md:flex items-center gap-5 my-8">
        <div>
          <label
            htmlFor="contractStartDate"
            className="block text-md font-medium leading-6 text-gray-900"
          >
            Contract Start Date
          </label>
          <div className="mt-2">
            <input
              {...register('contractStartDate')}
              type="date"
              id="contractStartDate"
              name="contractStartDate"
              max={contractEndDate ? contractEndDate : false}
              className="block w-52 rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
            />
          </div>
        </div>

        <div className="hidden md:block mt-6 text-2xl">-</div>

        <div>
          <label
            htmlFor="contractEndDate"
            className="block text-md font-medium leading-6 text-gray-900"
          >
            Contract End Date
          </label>
          <div className="mt-2">
            <input
              {...register('contractEndDate')}
              type="date"
              id="contractEndDate"
              name="contractEndDate"
              min={contractStartDate ? contractStartDate : false}
              className="block w-52 rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="my-8">
        <label
          htmlFor="contractEndDate"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Notes
        </label>
        <div className="mt-2">
          <textarea
            {...register('notes')}
            rows={3}
            name="notes"
            id="notes"
            className="block w-full lg:w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      {product?.productCategory?.name !== 'Miscellaneous & Business Utilities' && (
        <>
          <div className="my-8">
            <Controller
              control={control}
              name="DPIAStatus"
              render={({ field: { onChange, value } }) => (
                <div>
                  <RadioGroup value={value} onChange={onChange} className="mt-2">
                    <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
                      Data Protection Impact Assessment (DPIA)
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-sm text-gray-500">
                      Read more about{' '}
                      <a
                        className="hover:text-gray-600 underline "
                        href="https://digital.nhs.uk/services/nhs-login/data-protection-impact-assessment"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        DPIA on the NHS Digital site
                      </a>
                      .
                    </RadioGroup.Description>
                    <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-3 lg:grid-cols-4">
                      {completionOptions.map(option => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option.name}
                          className={({ active, checked }) =>
                            classNames(
                              active ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                              checked
                                ? 'bg-blue-600 text-white hover:bg-blue-500'
                                : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                              'flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1'
                            )
                          }
                        >
                          <RadioGroup.Label className="flex gap-1 items-center" as="span">
                            {option.name === value && <CheckCircleIcon className="h-5 w-5" />}
                            {option.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              )}
            />
          </div>

          <div className="my-8">
            <Controller
              control={control}
              name="DCB0160Status"
              render={({ field: { onChange, value } }) => (
                <div>
                  <RadioGroup value={value} onChange={onChange} className="mt-2">
                    <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
                      Clinical Risk Safety - DCB0160 documentation
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-sm text-gray-500">
                      DCB0160 is a mandatory UK risk management standard and healthcare
                      organisations implementing IT systems must comply, read more about{' '}
                      <a
                        className="hover:text-gray-600 underline "
                        href="https://digital.nhs.uk/data-and-information/information-standards/information-standards-and-data-collections-including-extractions/publications-and-notifications/standards-and-collections/dcb0160-clinical-risk-management-its-application-in-the-deployment-and-use-of-health-it-systems"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        DCB0160 on the NHS Digital site
                      </a>
                      .
                    </RadioGroup.Description>
                    <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-3 lg:grid-cols-4">
                      {completionOptions.map(option => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option.name}
                          className={({ active, checked }) =>
                            classNames(
                              active ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                              checked
                                ? 'bg-blue-600 text-white hover:bg-blue-500'
                                : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                              'flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1'
                            )
                          }
                        >
                          <RadioGroup.Label className="flex gap-1 items-center" as="span">
                            {option.name === value && <CheckCircleIcon className="h-5 w-5" />}
                            {option.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              )}
            />
          </div>

          <div className="my-8">
            <Controller
              control={control}
              name="sharingAgreementStatus"
              render={({ field: { onChange, value } }) => (
                <div>
                  <RadioGroup value={value} onChange={onChange} className="mt-2">
                    <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
                      Data Sharing Agreement
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-sm text-gray-500">
                      Not always applicable, but relevant when sharing data between organisations.
                    </RadioGroup.Description>
                    <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-3 lg:grid-cols-4">
                      {completionOptions.map(option => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option.name}
                          className={({ active, checked }) =>
                            classNames(
                              active ? 'ring-2 ring-blue-600 ring-offset-2' : '',
                              checked
                                ? 'bg-blue-600 text-white hover:bg-blue-500'
                                : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                              'flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1'
                            )
                          }
                        >
                          <RadioGroup.Label className="flex gap-1 items-center" as="span">
                            {option.name === value && <CheckCircleIcon className="h-5 w-5" />}
                            {option.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              )}
            />
          </div>
        </>
      )}
    </form>
  )
}

export default ProductForm
