import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { classNames } from '../utilities/general'

const ProductSurveyTypeRadioGroup = ({ value, onChange }) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className="block text-md font-semibold leading-6 text-gray-900">
        Select survey type
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-2 sm:gap-x-4">
        <RadioGroup.Option
          value={'pre-implementation'}
          className={({ active }) =>
            classNames(
              active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
              'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
            )
          }
        >
          {({ checked, active }) => (
            <>
              <span className="flex flex-1 gap-3 items-start">
                <span>
                  <span className="flex flex-col item">
                    <RadioGroup.Label
                      as="span"
                      className="block text-base font-semibold text-gray-900"
                    >
                      Pre-implementation
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-base text-gray-500"
                    >
                      The pre-implementation survey supports the practice of effective clinical
                      safety by bringing together all the relevant roles across the health
                      organisation to provide unbiased opinions when assessing risk.
                    </RadioGroup.Description>
                  </span>
                  {/* <div className="mt-4 text-sm">
                  {" "}
                  <Button
                    className="w-full"
                    variant="soft"
                    color="gray"
                    onClick={() => {}}
                  >
                    Preview Survey
                  </Button>
                </div> */}
                </span>
              </span>
              <CheckCircleIcon
                className={classNames(!checked ? 'invisible' : '', 'h-6 w-6 text-blue-600')}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  active ? 'border' : 'border-2',
                  checked ? 'border-blue-600' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-lg'
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option
          value={'post-implementation'}
          className={({ active }) =>
            classNames(
              active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300',
              'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
            )
          }
        >
          {({ checked, active }) => (
            <>
              <span className="flex flex-1 gap-3 items-start">
                <span>
                  <span className="flex flex-col item">
                    <RadioGroup.Label
                      as="span"
                      className="block text-base font-semibold text-gray-900"
                    >
                      Post-implementation
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-base text-gray-500"
                    >
                      The post-implementation survey ensures the maximisation of return on
                      investment and the maintenance of clinical safety standards by capturing
                      ongoing feedback from roles across the health organisation throughout the
                      technology's lifecycle.
                    </RadioGroup.Description>
                  </span>
                  {/* <div className="mt-4 text-sm">
                  {" "}
                  <Button
                    className="w-full"
                    variant="soft"
                    color="gray"
                    onClick={() => {}}
                  >
                    Preview Survey
                  </Button>
                </div> */}
                </span>
              </span>
              <CheckCircleIcon
                className={classNames(!checked ? 'invisible' : '', 'h-6 w-6 text-blue-600')}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  active ? 'border' : 'border-2',
                  checked ? 'border-blue-600' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-lg'
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  )
}

export default ProductSurveyTypeRadioGroup
