import React from 'react'

const CQCIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.0306 47.1759C47.6013 42.5199 51.8946 34.8626 51.8946 26.196C51.8946 12.004 40.3893 0.499939 26.1987 0.499939C12.004 0.499939 0.5 12.004 0.5 26.196C0.5 40.3893 12.004 51.8933 26.1987 51.8933H26.9293C33.764 52.0093 38.4613 54.2786 41.4613 57.9679C42.4693 57.304 49.752 51.0333 49.752 51.0333C49.752 51.0333 47.144 47.8839 41.0306 47.1759ZM25.6307 45.3173C32.1467 43.9906 37.4667 39.3466 39.808 33.2093C39.7948 33.2017 39.7774 33.2055 39.76 33.2168C37.2173 38.1222 32.104 41.4755 26.1987 41.4755C17.756 41.4755 10.916 34.6342 10.916 26.1955C10.916 17.7568 17.756 10.9168 26.1987 10.9168C32.104 10.9168 37.22 14.2728 39.7627 19.1781C39.7785 19.1933 39.7959 19.1971 39.808 19.1819C37.4666 13.0446 32.1453 8.40193 25.6306 7.07392C25.844 7.06636 26.0546 7.05881 26.2693 7.05881C36.7346 7.05881 45.216 15.6268 45.216 26.1948C45.216 36.7655 36.7346 45.3348 26.2693 45.3348C26.0546 45.3348 25.844 45.3249 25.6307 45.3173Z"
      fill="#893277"
    />
  </svg>
)

export default CQCIcon
