import React, { Fragment } from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

import { titleCaseNHS } from '../../../utilities/ods'
import { classNames } from '../../../utilities/general'
import { calculateStackCost } from '../../../utilities/stacks'
import ProductImage from '../../ProductImage'
import { PriceSwitch } from '../DashboardSummary/'
import ContractBadge from '../../ContractBadge'
import LoadingIcon from '../../LoadingIcon'

const calculateAssessmentCompletion = stackManagementDetails => {
  let dpiaCompleted = 0
  let dcb0160Completed = 0
  let sharingAgreementCompleted = 0

  let dpiaTotal = 0
  let dcb0160Total = 0
  let sharingAgreementTotal = 0

  stackManagementDetails.forEach(stackManagementDetail => {
    if (stackManagementDetail.DPIAStatus === 'Completed') dpiaCompleted++
    if (stackManagementDetail.DCB0160Status === 'Completed') dcb0160Completed++
    if (stackManagementDetail.sharingAgreementStatus === 'Completed') sharingAgreementCompleted++
  })

  stackManagementDetails.forEach(stackManagementDetail => {
    if (
      stackManagementDetail.DPIAStatus === 'Completed' ||
      stackManagementDetail.DPIAStatus === 'Not completed'
    )
      dpiaTotal++
    if (
      stackManagementDetail.DCB0160Status === 'Completed' ||
      stackManagementDetail.DCB0160Status === 'Not completed'
    )
      dcb0160Total++
    if (
      stackManagementDetail.sharingAgreementStatus === 'Completed' ||
      stackManagementDetail.sharingAgreementStatus === 'Not completed'
    )
      sharingAgreementTotal++
  })

  return {
    dpiaCompleted,
    dcb0160Completed,
    sharingAgreementCompleted,
    dpiaTotal,
    dcb0160Total,
    sharingAgreementTotal,
  }
}

const Table = ({
  loadingPheData,
  downloadingXLS,
  stack,
  pheDetails,
  perPatientPricing,
  setPerPatientPricing,
}) => {
  const stackName = stack?.organisation?.name || stack.name
  const patientCount = pheDetails?.population?.ListSize?.Val
  const stackManagementDetails = stack?.StackProductManagements?.items
  const stackCost = calculateStackCost(
    stack,
    stackManagementDetails,
    perPatientPricing,
    patientCount
  )
  const assessmentCompletion = calculateAssessmentCompletion(stackManagementDetails)

  return (
    <div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="border border-gray-300 rounded-md overflow-hidden">
              <table id="table" className="min-w-full" data-cols-width="40,20,20,20,12,12,12">
                <thead className="bg-white">
                  <tr className="align-top" data-height="40">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      <div className="mb-2">Price</div>
                      <PriceSwitch
                        perPatientPricing={perPatientPricing}
                        setPerPatientPricing={setPerPatientPricing}
                      />
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      Contract End Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      DPIA
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      Clinical risk safety
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      data-f-bold
                      data-fill-color="FFE5E7EB"
                    >
                      Data sharing agreement
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  <Fragment key={stack.id}>
                    <tr className="border-t border-gray-200" data-height="35">
                      <th
                        colSpan={7}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-3"
                        data-f-sz="14"
                        data-fill-color="FFF3F4F6"
                        data-f-bold
                      >
                        <div className="flex items-center">
                          <div className="text-lg">{stackName}</div>

                          {stack?.organisation?.pcnName && (
                            <span className="ml-2 inline-flex items-center rounded-md bg-green-100 px-2 py-0.5 text-sm font-medium text-green-700">
                              {downloadingXLS && <> (</>}
                              {titleCaseNHS(stack?.organisation?.pcnName)}
                              {downloadingXLS && <>)</>}
                            </span>
                          )}
                        </div>
                        {stack?.organisation?.icbName && (
                          <p className="mt-1 max-w-2xl text-sm font-normal leading-6 text-gray-500">
                            {downloadingXLS && <br />}
                            {titleCaseNHS(stack?.organisation.icbName)}
                          </p>
                        )}
                      </th>
                    </tr>
                    {stack.SoftwareProducts?.items.length === 0 ? (
                      <tr className="w-full" data-height="20">
                        <td
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          colspan="7"
                        >
                          No products
                        </td>
                      </tr>
                    ) : (
                      <>
                        {stack.SoftwareProducts?.items.map((product, productIndex) => {
                          const selectedProductManagementDetail = stackManagementDetails.find(
                            productManagementDetail => {
                              const isSameProduct =
                                productManagementDetail.productManagementDetailsSoftwareProductId ===
                                product.softwareProductId
                              const isSameStack =
                                productManagementDetail.softwarestackID === stack.id
                              return isSameProduct && isSameStack
                            }
                          )

                          const contractEndDate =
                            selectedProductManagementDetail?.contractEndDate &&
                            new Date(selectedProductManagementDetail?.contractEndDate)

                          let price
                          if (selectedProductManagementDetail?.price) {
                            if (perPatientPricing) {
                              if (selectedProductManagementDetail?.pricingPlan === 'MONTHLY') {
                                price = `${(
                                  (selectedProductManagementDetail?.price * 12) /
                                  patientCount
                                ).toFixed(2)} per patient yearly`
                              } else if (
                                selectedProductManagementDetail?.pricingPlan === 'YEARLY'
                              ) {
                                price = `${(
                                  selectedProductManagementDetail?.price / patientCount
                                ).toFixed(2)} per patient yearly`
                              } else if (
                                selectedProductManagementDetail?.pricingPlan ===
                                'YEARLY_PER_PATIENT'
                              ) {
                                price = `${selectedProductManagementDetail?.price.toFixed(
                                  2
                                )} per patient yearly`
                              }
                            } else {
                              if (selectedProductManagementDetail?.pricingPlan === 'MONTHLY') {
                                price = `${(selectedProductManagementDetail?.price * 12).toFixed(
                                  2
                                )} yearly`
                              } else if (
                                selectedProductManagementDetail?.pricingPlan === 'YEARLY'
                              ) {
                                price = `${(selectedProductManagementDetail?.price).toFixed(
                                  2
                                )} yearly`
                              } else if (
                                selectedProductManagementDetail?.pricingPlan ===
                                'YEARLY_PER_PATIENT'
                              ) {
                                price = `${(
                                  selectedProductManagementDetail?.price * patientCount
                                ).toFixed(2)} yearly`
                              }
                            }
                          }

                          let procuredLabel = ''
                          if (selectedProductManagementDetail?.procuredInternally === true)
                            procuredLabel = '(Procured Internally)'
                          if (selectedProductManagementDetail?.procuredInternally === false)
                            procuredLabel = '(Procured Externally)'

                          return (
                            <tr
                              key={product.id}
                              className={classNames(
                                productIndex === 0 ? 'border-gray-300' : 'border-gray-200',
                                'border-t'
                              )}
                              data-height="30"
                            >
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-3">
                                <span className="flex gap-4 items-center">
                                  <ProductImage
                                    softwareProduct={product.softwareProduct}
                                    width="w-12"
                                    height="h-12"
                                    hideBorder
                                  />
                                  {product.softwareProduct.name}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {product.softwareProduct.productCategory?.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {loadingPheData ? (
                                  <LoadingIcon height="h-5" width="w-5" />
                                ) : (
                                  <>
                                    {selectedProductManagementDetail?.price ? (
                                      <>
                                        {perPatientPricing && !stack?.organisation?.odsId ? (
                                          <span>Unavailable</span>
                                        ) : (
                                          <>
                                            <div>£{price}</div>
                                            <div>{procuredLabel}</div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {selectedProductManagementDetail?.contractEndDate ? (
                                  <ContractBadge contractEndDate={contractEndDate} size="xs" />
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              {selectedProductManagementDetail?.DPIAStatus === 'Completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FF16A34A"
                                  data-f-sz="16"
                                >
                                  <CheckCircleIcon className="h-5 w-5 text-green-600" />
                                  {downloadingXLS && <>✓</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.DPIAStatus === 'Not completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FFDC2626"
                                  data-f-sz="16"
                                >
                                  <XCircleIcon className="h-5 w-5 text-red-600" />
                                  {downloadingXLS && <>x</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.DPIAStatus !== 'Completed' &&
                                selectedProductManagementDetail?.DPIAStatus !== 'Not completed' && (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <>-</>
                                  </td>
                                )}

                              {selectedProductManagementDetail?.DCB0160Status === 'Completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FF16A34A"
                                  data-f-sz="16"
                                >
                                  <CheckCircleIcon className="h-5 w-5 text-green-600" />
                                  {downloadingXLS && <>✓</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.DCB0160Status ===
                                'Not completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FFDC2626"
                                  data-f-sz="16"
                                >
                                  <XCircleIcon className="h-5 w-5 text-red-600" />
                                  {downloadingXLS && <>x</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.DCB0160Status !== 'Completed' &&
                                selectedProductManagementDetail?.DCB0160Status !==
                                  'Not completed' && (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <>-</>
                                  </td>
                                )}

                              {selectedProductManagementDetail?.sharingAgreementStatus ===
                                'Completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FF16A34A"
                                  data-f-sz="16"
                                >
                                  <CheckCircleIcon className="h-5 w-5 text-green-600" />
                                  {downloadingXLS && <>✓</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.sharingAgreementStatus ===
                                'Not completed' && (
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  data-f-color="FFDC2626"
                                  data-f-sz="16"
                                >
                                  <XCircleIcon className="h-5 w-5 text-red-600" />
                                  {downloadingXLS && <>x</>}
                                </td>
                              )}
                              {selectedProductManagementDetail?.sharingAgreementStatus !==
                                'Completed' &&
                                selectedProductManagementDetail?.sharingAgreementStatus !==
                                  'Not completed' && (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <>-</>
                                  </td>
                                )}
                            </tr>
                          )
                        })}
                        <tr data-height="30">
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          >
                            Summary
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          ></td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          >
                            {loadingPheData ? (
                              <LoadingIcon height="h-5" width="w-5" />
                            ) : (
                              <>
                                {perPatientPricing ? (
                                  <>
                                    {!patientCount ? (
                                      'Unavailable'
                                    ) : (
                                      <>£{stackCost.toFixed(2)} per patient yearly</>
                                    )}
                                  </>
                                ) : (
                                  <>£{stackCost.toFixed(2)} yearly</>
                                )}
                              </>
                            )}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          ></td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          >
                            {assessmentCompletion.dpiaCompleted}/{assessmentCompletion.dpiaTotal}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          >
                            {assessmentCompletion.dcb0160Completed}/
                            {assessmentCompletion.dcb0160Total}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-800"
                            data-f-bold
                          >
                            {assessmentCompletion.sharingAgreementCompleted}/
                            {assessmentCompletion.sharingAgreementTotal}
                          </td>
                        </tr>
                      </>
                    )}
                  </Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table
