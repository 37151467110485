import React, { useState, useEffect } from 'react'
import { generateClient, post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import { listSoftwareStacks } from '../../../graphql/queries-static'
import useProducts from '../../../hooks/useProducts'
import useProductFilters from '../../../hooks/useProductFilters'
import ProgressStepper from '../../ProgressStepper'
import OrganisationSelector from '../../OrganisationSelector'
import SelectProduct from '../../SelectProduct'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import LoadIcon from '../../LoadingIcon'
import CopyToClipboard from '../../CopyToClipboard'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'
import surveyQuestions from '../../data/surveyQuestions'
import { replaceTags } from '../../../utilities/general'
import WhatsAppShareButton from '../../WhatsAppShareButton'
import ProductSurveyTypeRadioGroup from '../../ProductSurveyTypeRadioGroup'

const client = generateClient()

const ProductSurveySetupPage = ({ cognitoUser }) => {
  const [step, setStep] = useState(1)
  const [stackList, setStackList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedSurveyType, setSelectedSurveyType] = useState('pre-implementation')
  const [selectedStackId, setSelectedStackId] = useState()
  const [loadingStackList, setLoadingStackList] = useState(false)
  const [loadingCreateSurvey, setLoadingCreateSurvey] = useState(false)
  const [surveyUniqueCode, setSurveyUniqueCode] = useState()
  const { loadingProducts, products } = useProducts()
  const { loadingProductFilters, productFilters } = useProductFilters()

  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const stackIdFromUrl = urlParams.get('stackId')

  useEffect(() => {
    if (stackIdFromUrl) {
      setSelectedStackId(stackIdFromUrl)
    }
  }, [])

  const getStackList = async () => {
    setLoadingStackList(true)
    const softwareStacks = await client.graphql({
      query: listSoftwareStacks,
      variables: {
        limit: 1000,
        filter: {
          or: [
            { owner: { eq: cognitoUser.userId } },
            { administrator: { contains: cognitoUser.userId } },
            { readAccess: { contains: cognitoUser.userId } },
          ],
        },
      },
      authMode: 'userPool',
    })

    const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort((stackA, stackB) => {
      const getStackName = stack => {
        return stack?.organisation?.name || stack.name
      }
      const stackAName = getStackName(stackA)
      const stackBName = getStackName(stackB)

      if (stackAName > stackBName) return 1
      if (stackBName > stackAName) return -1
      return 0
    })

    const firstNonReadAccessStack = sortedStacks.find(stack => {
      const isReadAccess = stack?.readAccess?.includes(cognitoUser.userId)
      if (!isReadAccess) return stack
    })

    setStackList(sortedStacks)
    if (!stackIdFromUrl) setSelectedStackId(firstNonReadAccessStack.id)
    setLoadingStackList(false)
  }

  useEffect(() => {
    const fetchStacks = async () => {
      await getStackList()
    }
    fetchStacks()
  }, [])

  const createSurvey = async () => {
    setLoadingCreateSurvey(true)

    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    const body = {
      productIds: [selectedProduct.id],
      stackIds: [selectedStackId],
      subType: selectedSurveyType,
    }

    const restOperation = post({
      apiName: 'bordercrossRest',
      path: '/surveys/productSurvey/create',
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body,
      },
    })

    const response = await restOperation.response
    const surveyUniqueCode = await response.body.json()
    setSurveyUniqueCode(surveyUniqueCode)

    setLoadingCreateSurvey(false)
  }

  const steps = [
    {
      id: '01',
      name: 'Introduction',
      // onClick: () => setStep(1),
      status: step === 1 ? 'current' : 'complete',
    },
    {
      id: '02',
      name: 'Configure',
      // onClick: () => setStep(2),
      status: step === 1 ? 'upcoming' : step === 2 ? 'current' : 'complete',
    },
    {
      id: '03',
      name: 'Send',
      // onClick: () => setStep(3),
      status: step === 3 ? 'current' : step < 3 ? 'upcoming' : 'complete',
    },
  ]

  const loading = loadingStackList || !cognitoUser || loadingProducts || loadingProductFilters

  const surveyUrl = `${window.location.host?.startsWith('localhost') ? 'http' : 'https'}://${
    window.location.host
  }/ext/employee-insights/product-survey/${surveyUniqueCode}`

  const selectedStack = stackList?.find(stack => stack.id === selectedStackId)

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8">
      <div className="bg-white">
        <ProgressStepper steps={steps} clickable={false} />
      </div>

      <h1 className="mt-8 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
        Product Survey Setup
      </h1>
      <p className="mt-2 text-lg leading-8 text-gray-600">
        Get feedback from staff about the products used in your organisation
      </p>

      {loading ? (
        <LoadBox />
      ) : (
        <>
          <div className="mt-8">
            <div className="max-w-4xl">
              {step === 1 && (
                <>
                  <div className="text-lg space-y-4 mb-8">
                    {/* <p>
                      This survey contains{" "}
                      <strong>
                        {surveyQuestions.productSurvey.productQuestions.length}{" "}
                        statements
                      </strong>{" "}
                      designed to learn what your staff think about the
                      technology your staff are using in your practice.
                    </p> */}
                    <p>
                      It uses the <strong>Likert scale</strong> to measure attitudes from{' '}
                      <strong>
                        respondents expressing the degree to which they agree or disagree with a
                        statement
                      </strong>
                      .
                    </p>
                    <p>
                      Respondents can also provide <strong>free-text comments</strong> to these
                      statements which enrich the data gained by the survey.
                    </p>
                  </div>
                  <NextButton disabled={!selectedStackId} step={step} setStep={setStep} />
                </>
              )}
            </div>

            {step === 2 && (
              <>
                <OrganisationSelector
                  defaultValue={selectedStackId}
                  cognitoUser={cognitoUser}
                  stackList={stackList}
                  onChange={stackId => {
                    setSelectedStackId(stackId)
                    setSelectedProduct()
                  }}
                  disableReadStacks={true}
                />

                <div className="mt-8">
                  <ProductSurveyTypeRadioGroup
                    value={selectedSurveyType}
                    onChange={setSelectedSurveyType}
                  />
                </div>

                <div className="mt-8">
                  <label htmlFor="tabs" className="font-semibold">
                    Select product{' '}
                  </label>
                  <p className="text-sm text-gray-600">
                    This is the product we'll include in the survey.
                  </p>
                  <div className="mt-3">
                    <SelectProduct
                      products={products}
                      selectedProduct={selectedProduct}
                      setSelectedProduct={setSelectedProduct}
                      filters={productFilters}
                    />
                  </div>
                </div>
                <NextButton
                  disabled={!selectedProduct}
                  step={step}
                  setStep={setStep}
                  callback={createSurvey}
                  loading={loadingCreateSurvey}
                />
              </>
            )}

            {step === 3 && (
              <div className="mt-6">
                <div className="flex gap-5">
                  <span className="relative flex h-5 w-5 mt-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-5 w-5 bg-indigo-500"></span>
                  </span>
                  <div className="w-full">
                    <h3 className="font-semibold text-2xl">Your survey is now live</h3>
                    <p className="text-lg mt-4">
                      Share the link with your colleagues to start receiving responses
                    </p>
                    <div className="max-w-[50rem] mt-2">
                      <CopyToClipboard label="Survey url" text={surveyUrl} />
                    </div>

                    {selectedStack.name && surveyUrl && (
                      <div className="mt-2">
                        <WhatsAppShareButton
                          organisationName={selectedStack.name}
                          surveyUrl={surveyUrl}
                        />
                      </div>
                    )}

                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-2">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-yellow-700">
                            Only share this link with people who need to fill out the survey. Anyone
                            with the link can submit a response.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-8">
                      <Link
                        className="text-center rounded-md bg-indigo-600 px-5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        to={`/employee-insights/product-survey/${surveyUniqueCode}`}
                      >
                        <span className="inline-flex gap-2 items-center">
                          Go to survey results page
                          <ArrowRightIcon className="h-5 w-5" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ProductSurveySetupPage

const NextButton = ({ disabled = false, step, setStep, callback, loading = false }) => (
  <button
    disabled={disabled}
    type="button"
    className={
      'mt-4 inline-flex items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-3 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-400 disabled:hover:bg-indigo-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2'
    }
    onClick={async () => {
      if (callback) await callback()
      setStep(step + 1)
    }}
  >
    <div className="flex items-center gap-2">
      {loading && <LoadIcon textColor="text-white" />}
      Next <ArrowRightIcon className="h-5 w-5 ml-1 " />
    </div>
  </button>
)
