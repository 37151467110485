import React, { useState, useRef } from 'react'
import './HTMLDisplayBox.css'

const HtmlDisplayBox = ({ content }) => {
  const contentRef = useRef(null)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = event => {
    if (contentRef.current) {
      const selection = window.getSelection()
      const range = document.createRange()

      // Select content inside the `contentRef`
      range.selectNodeContents(contentRef.current)
      selection.removeAllRanges()
      selection.addRange(range)

      // Get HTML content from the reference
      const htmlContent = contentRef.current.innerHTML

      // Set clipboard data with both plain text and HTML
      event.clipboardData.setData('text/plain', selection.toString())
      event.clipboardData.setData('text/html', htmlContent)

      // Prevent default browser copy behavior
      event.preventDefault()

      // Feedback for successful copy
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    }
  }

  const onButtonClick = () => {
    document.addEventListener('copy', handleCopy, { once: true }) // Trigger `handleCopy` on the next copy event
    document.execCommand('copy') // Programmatically trigger copy
  }

  return (
    <div className="border border-gray-300 bg-gray-50 rounded-md shadow-sm p-4 max-h-72 overflow-scroll relative">
      <div ref={contentRef} className="html-display-box-content mb-4">
        {content}
      </div>
      <button
        onClick={onButtonClick}
        className="absolute top-2 right-2 px-3 py-1 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        {isCopied ? 'Copied!' : 'Copy'}
      </button>
    </div>
  )
}

export default HtmlDisplayBox
