import { useRef, useState } from 'react'
import { generateClient } from 'aws-amplify/api'
import { useForm } from 'react-hook-form'

// import AddProductFilter from "./AddProductFilter";
// import ProductForm from "./ProductForm";
// import ProductImage from "./ProductImage";

import ProductForm from './ProductForm'

import {
  updateSoftwareProduct,
  createSoftwareProductProductTag,
  deleteSoftwareProductProductTag,
} from '../../../graphql/mutations-static'

const client = generateClient()

export default function EditProductModal({
  product,
  productFilters,
  open,
  stack,
  setOpen,
  getProducts,
  getStacks,
  preselectedAddProductFilter,
}) {
  const cancelButtonRef = useRef(null)
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const { register, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      ...product,
      tags: product?.productTags?.items?.map(productTag => productTag.productTag),
    },
  })

  const handleEditProduct = async data => {
    setSubmissionLoading(true)

    const {
      id,
      name,
      publisher,
      description,
      imageUrl,
      link,
      productCategory,
      tags,
      dcb0129TransferredRisks,
      dcb0129OrDPIAProductDescription,
    } = data

    const editedSoftwareProduct = await client.graphql({
      query: updateSoftwareProduct,
      variables: {
        input: {
          id,
          name,
          publisher,
          description,
          imageUrl,
          link,
          productcategoryID: productCategory.id,
          dcb0129TransferredRisks,
          dcb0129OrDPIAProductDescription,
        },
      },
      authMode: 'userPool',
    })

    if (product?.productTags?.items) {
      await Promise.all(
        product?.productTags?.items.map(async tag => {
          await client.graphql({
            query: deleteSoftwareProductProductTag,
            variables: {
              input: {
                id: tag.id,
              },
            },
            authMode: 'userPool',
          })
        })
      )
    }

    if (tags.length) {
      await Promise.all(
        tags.map(async tag => {
          await client.graphql({
            query: createSoftwareProductProductTag,
            variables: {
              input: {
                productTagId: tag.id,
                softwareProductId: editedSoftwareProduct.data.updateSoftwareProduct.id,
              },
            },
            authMode: 'userPool',
          })
        })
      )
    }

    await getProducts()
    setSubmissionLoading(false)
    setOpen(false)
  }

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll">
        <div className="flex gap-2 items-center">
          <h2 className="text-xl sm:text-4xl font-bold">Edit product</h2>
        </div>

        <ProductForm
          product={product}
          productFilters={productFilters}
          register={register}
          handleSubmit={handleSubmit}
          watch={watch}
          setValue={setValue}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse sm:gap-3">
        <button
          disabled={submissionLoading ? true : false}
          type="button"
          className={
            'inline-flex items-center w-full justify-center rounded-md bg-blue-100 text-blue-600 hover:bg-blue-200 px-3 py-2 text-sm font-semibold shadow-sm disabled:bg-blue-50 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
          }
          onClick={handleSubmit(data => handleEditProduct(data))}
        >
          {submissionLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Update
        </button>

        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  )
}
