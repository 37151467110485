import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import SuccessAlert from '../SuccessAlert'

const AccountPage = ({ user }) => {
  const [loadingUpdateNotification, setLoadingUpdateNotification] = useState(false)
  const [showSuccessfulUpdateNotification, setShowSuccessfulUpdateNotification] = useState(false)
  const { register, handleSubmit, reset } = useForm()

  // Updates default form values once user has loaded
  useEffect(() => {
    reset({
      recieveContractEndEmailNotifications:
        user?.optOutContractEndEmailNotificaitons === false ? true : false,
    })
  }, [user])

  const onSubmit = async data => {
    setShowSuccessfulUpdateNotification(false)
    setLoadingUpdateNotification(true)

    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    const body = {
      preferences: {
        optOutContractEndEmailNotificaitons: !data.recieveContractEndEmailNotifications,
      },
    }

    const restOperation = post({
      apiName: 'bordercrossRest',
      path: '/users/updateNotificationPreferences',
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body,
      },
    })

    await restOperation.response

    setShowSuccessfulUpdateNotification(true)
    setLoadingUpdateNotification(false)
  }

  if (!user) return

  return (
    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Account</h1>

      <div className="mt-6 flex gap-4">
        <div className="h-12 w-12 text-2xl rounded-full bg-blue-500 flex items-center justify-center text-white">
          {user?.givenName.charAt(0)}
        </div>
        <div>
          <div className="text-lg font-bold tracking-tight text-gray-900 sm:text-xl">
            {user.givenName} {user.familyName}
          </div>
          <div className="text-gray-600 text-base sm:text-lg">{user.email}</div>
        </div>
      </div>

      <div className="mt-14">
        <div className="max-w-xl mt-6">
          <fieldset>
            <legend className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Notification preferences
            </legend>
            <div className="space-y-5 mt-4">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    {...register('recieveContractEndEmailNotifications')}
                    id="recieveContractEndEmailNotifications"
                    aria-describedby="recieveContractEndEmailNotifications-description"
                    name="recieveContractEndEmailNotifications"
                    type="checkbox"
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-base leading-6">
                  <label
                    htmlFor="recieveContractEndEmailNotifications"
                    className="font-semibold text-gray-900"
                  >
                    Contract renewal reminders
                  </label>
                  <p
                    id="recieveContractEndEmailNotifications-description"
                    className="text-gray-500 text-sm"
                  >
                    Receive email reminders about products in your organisation(s) with contracts
                    expiring soon.
                  </p>
                </div>
              </div>
            </div>
          </fieldset>

          <button
            type="button"
            onClick={handleSubmit(data => onSubmit(data))}
            className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loadingUpdateNotification && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Save
          </button>

          {showSuccessfulUpdateNotification && (
            <div className="mt-4">
              <SuccessAlert
                text="Successfully updated notification preferences"
                onDismiss={() => setShowSuccessfulUpdateNotification(false)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountPage
