import React from 'react'

import { RatingArrow } from './'

const DeprivationScore = ({ pheDetails }) => {
  return (
    <div className="pr-6">
      <div className="grid grid-cols-10">
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 1 && (
            <RatingArrow bg="bg-red-700" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 2 && (
            <RatingArrow bg="bg-red-600" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 3 && (
            <RatingArrow bg="bg-red-500" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 4 && (
            <RatingArrow bg="bg-amber-600" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 5 && (
            <RatingArrow bg="bg-amber-500" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 6 && (
            <RatingArrow bg="bg-amber-400" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 7 && (
            <RatingArrow bg="bg-amber-300" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 8 && (
            <RatingArrow bg="bg-green-400" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 9 && (
            <RatingArrow bg="bg-green-500" />
          )}
        </div>
        <div className="flex justify-center my-2">
          {pheDetails?.populationSummary?.GpDeprivationDecile === 10 && (
            <RatingArrow bg="bg-green-600" />
          )}
        </div>
        <div className="bg-red-700 h-4"></div>
        <div className="bg-red-600 h-4"></div>
        <div className="bg-red-500 h-4"></div>
        <div className="bg-amber-600 h-4"></div>
        <div className="bg-amber-500 h-4"></div>
        <div className="bg-amber-400 h-4"></div>
        <div className="bg-amber-300 h-4"></div>
        <div className="bg-green-400 h-4"></div>
        <div className="bg-green-500 h-4"></div>
        <div className="bg-green-600 h-4"></div>
      </div>

      <div className="flex justify-between mt-1">
        <div className="text-gray-500 text-xs">More deprived</div>
        <div className="text-gray-500 text-xs text-right">Less deprived</div>
      </div>
      {/* <div className="text-center">
        CQC Rating:{" "}
        <span className="font-bold">
          {getCQCRatingText(pheDetails?.populationSummary?.GpDeprivationDecile)}
        </span>
      </div>
      <div className="text-center text-gray-500 text-sm leading-4">
        {getCQCRatingDescription(pheDetails?.populationSummary?.GpDeprivationDecile)}
      </div> */}

      {/* <div className="flex gap-1 justify-center text-sm mt-1 text-gray-500">
        Report published{" "}
        {new Date(
          pheDetails?.populationSummary?.CQCRatingReportDate
        ).toLocaleDateString()}{" "}
        |{" "}
        <a
          className="flex gap-1 items-center underline hover:text-gray-800"
          href={pheDetails?.populationSummary?.CQCRatingReportLink}
        >
          View report <ArrowRightIcon className="h-3 w-3 mt-1" />
        </a>
      </div> */}
    </div>
  )
}

export default DeprivationScore
