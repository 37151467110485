import { useRef, useState } from 'react'
import { generateClient, post } from 'aws-amplify/api'
import { useForm } from 'react-hook-form'
import { fetchAuthSession } from 'aws-amplify/auth'

import AddProductFilter from './AddProductFilter'
import ProductForm from './ProductForm'
import ProductImage from './ProductImage'
import {
  createSoftwareStackSoftwareProduct,
  createProductManagementDetails,
} from '../graphql/mutations-static'

const client = generateClient()

export default function AddProductModal({
  products,
  productFilters,
  stack,
  setOpen,
  getStack,
  preselectedAddProductFilter,
}) {
  const cancelButtonRef = useRef(null)
  const [selectedProduct, setSelectedProduct] = useState()
  const [stage, setStage] = useState(1)
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const { register, handleSubmit, watch, reset, control } = useForm({
    defaultValues: {
      sharingAgreementStatus: 'Not required',
      DCB0160Status: 'Not required',
      DPIAStatus: 'Not required',
      procuredInternally: 'internal',
    },
  })

  const addStackProduct = async productId => {
    try {
      await client.graphql({
        query: createSoftwareStackSoftwareProduct,
        variables: {
          input: {
            softwareStackId: stack.id,
            softwareProductId: productId,
          },
        },
        authMode: 'userPool',
      })
    } catch (e) {}
  }

  const handleAddProduct = async (data, keepModalOpen) => {
    setSubmissionLoading(true)

    await addStackProduct(selectedProduct.id)

    let procuredInternally
    if (data?.procuredInternally === 'internal' || data?.procuredInternally === 'external') {
      procuredInternally = data.procuredInternally === 'internal' ? true : false
    }

    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    await post({
      apiName: 'bordercrossRest',
      path: '/stacks/productManagementDetails/create',
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body: {
          input: {
            softwarestackID: stack.id,
            productManagementDetailsSoftwareProductId: selectedProduct.id,
            DCB0160Status: data.DCB0160Status,
            DPIAStatus: data.DPIAStatus,
            sharingAgreementStatus: data.sharingAgreementStatus,
            contractStartDate: data.contractStartDate || null,
            contractEndDate: data.contractEndDate || null,
            price: data.price || null,
            pricingPlan: data.pricingPlan,
            procuredInternally: procuredInternally,
            owner: stack.owner,
            administrator: stack.administrator,
            readAccess: stack.readAccess,
            notes: data.notes,
          },
        },
      },
    }).response

    await getStack(stack.id, false)
    setSubmissionLoading(false)
    if (keepModalOpen) {
      setStage(1)
      reset()
    } else {
      setOpen(false)
    }
  }

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll p-1">
        {stage === 1 && (
          <AddProductFilter
            products={products}
            filters={productFilters}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            stack={stack}
            preselectedAddProductFilter={preselectedAddProductFilter}
          />
        )}
        {stage === 2 && (
          <>
            <div className="flex gap-2 items-center">
              <ProductImage softwareProduct={selectedProduct} height="h-16" width="w-16" />
              <h2 className="text-xl sm:text-4xl font-bold">Add {selectedProduct.name}</h2>
            </div>

            <h3 className="sm:text-xl font-bold text-gray-900 mt-6">Add product details</h3>
            <ProductForm
              product={selectedProduct}
              control={control}
              watch={watch}
              register={register}
              stack={stack}
            />
          </>
        )}
      </div>

      <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse sm:gap-3">
        {stage === 1 && (
          <button
            disabled={selectedProduct ? false : true}
            type="button"
            className={
              'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-400 disabled:hover:bg-blue-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
            }
            onClick={() => setStage(stage + 1)}
          >
            Next
          </button>
        )}
        {stage === 2 && (
          <>
            <button
              disabled={submissionLoading ? true : false}
              type="button"
              className={
                'inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-400 disabled:hover:bg-blue-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
              }
              onClick={handleSubmit(data => handleAddProduct(data, true))}
            >
              {submissionLoading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Save & add another
            </button>
            <button
              disabled={submissionLoading ? true : false}
              type="button"
              className={
                'inline-flex items-center w-full justify-center rounded-md bg-blue-100 text-blue-600 hover:bg-blue-200 px-3 py-2 text-sm font-semibold shadow-sm disabled:bg-blue-50 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2'
              }
              onClick={handleSubmit(data => handleAddProduct(data, false))}
            >
              {submissionLoading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Save & close
            </button>
          </>
        )}

        {stage === 1 && (
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => setOpen(false)}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        )}
        {stage === 2 && (
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => setStage(stage - 1)}
            ref={cancelButtonRef}
          >
            Back
          </button>
        )}
      </div>
    </>
  )
}
