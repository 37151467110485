import React from 'react'
import { TagIcon, LinkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import ProductImage from '../../ProductImage'
import RecommendedProductStepper from '../../RecommendedProductStepper'

const ProductBoxItem = ({ product }) => {
  if (!product) return

  return (
    <li
      key={product.id}
      className="relative isolate flex flex-col gap-8 lg:flex-row mb-8 bg-white p-4 rounded-md"
    >
      <ProductImage softwareProduct={product} width="w-24" height="h-24" />
      <div className="mb-1 w-full">
        <div className="flex justify-between">
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              {product.productCategory?.name && (
                <a
                  href={`/products?filter=${product.productCategory.id}`}
                  className="relative z-10 rounded-md bg-sky-100 px-3 py-1.5 font-medium text-sky-600 hover:bg-sky-50"
                >
                  {product.productCategory.name}
                </a>
              )}
            </div>

            <div className="group relatives">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:underline group-hover:text-gray-600">
                <Link to={`/product/${product.id}`}>
                  <span className="absolute inset-0" />
                  {product.name}
                </Link>
              </h3>
              {product.publisher && (
                <p className="text-sm leading-6 text-gray-600">{product.publisher}</p>
              )}
              {product.link && (
                <a
                  className="flex gap-2 mt-2 relative text-sm items-center text-gray-600 hover:underline break-all hover:text-gray-700"
                  href={product.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkIcon className="h-4 w-4" />
                  {product.link}
                </a>
              )}
              {product.description && (
                <p className="mt-3 text-sm leading-6 text-gray-600 max-w-xl">
                  {product.description}
                </p>
              )}
            </div>
          </div>

          {product?.percentageRecommend && (
            <RecommendedProductStepper value={parseInt(product.percentageRecommend)} />
          )}
        </div>

        {product.productTags.items.length > 0 && (
          <div className="flex items-center gap-2 mt-2 flex-wrap">
            <TagIcon className="h-4 w-4 text-indigo-600" />
            {product.productTags.items.map(({ productTag }, index) => (
              <>
                {index !== 0 && <span className="text-gray-300">|</span>}
                <a
                  className="inline-flex items-center rounded-md text-sm font-medium text-indigo-600 hover:underline hover:text-indigo-700"
                  href={`/products?filter=${productTag.id}`}
                >
                  {productTag.name}
                </a>
              </>
            ))}
          </div>
        )}

        {/* <div className="mt-6 flex border-t pt-6">
          <div className="relative flex items-center gap-x-4">
            <img
              src={product.author.imageUrl}
              alt=""
              className="h-10 w-10 rounded-full bg-gray-50"
            />
            <div className="text-sm leading-6">
              <p className="font-semibold text-gray-900">
                
                <a href={product.author.href}>
                  <span className="absolute inset-0" />
                  {product.author.name}
                </a>
              </p>
              <p className="text-gray-600">{product.author.role}</p>
            </div>
          </div>
        </div> */}
      </div>
    </li>
  )

  //  <li
  //    key={product.name}
  //    className="relative bg-gray-100 p-4 inline-flex flex-col items-center"
  //  >
  //    <div>
  //      <img
  //        src={`${process.env.PUBLIC_URL}/images/product-logos/${product.imageUrl}`}
  //        alt={`${product.name} Logo`}
  //        className="h-20"
  //      />
  //    </div>
  //    {/* <button
  //                       type="button"
  //                       className="absolute inset-0 focus:outline-none"
  //                     >
  //                       <span className="sr-only">
  //                         View details for {file.title}
  //                       </span>
  //                     </button> */}

  //    <h3 className="mt-3 text-md font-medium leading-6 text-gray-900">
  //      {product.name}
  //    </h3>
  //  </li>;

  // return (
  //     <div className="box selected" onClick={() => setSelectedProduct(product)}>
  //         <img src={`${process.env.PUBLIC_URL}${product.img}`} alt={product.name} className="product-image" />
  //         {/* Display additional product details */}
  //         <div className="product-details">
  //             {product.annualPrice && <p>Annual Price: {product.annualPrice}</p>}
  //             {product.contractStart && <p>Contract Start: {product.contractStart}</p>}
  //             {product.contractEnd && <p>Contract End: {product.contractEnd}</p>}
  //             {/* Add more details as needed */}
  //         </div>
  //     </div>
  // );
}

export default ProductBoxItem
