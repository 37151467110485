import React from 'react'

import AddProductModal from '../../AddProductModal'
import EditProductModal from '../../EditProductModal'
import ManageUsersModal from '../../ManageUsersModal'
import Modal from '../../Modal'
import Dialog from '../../Dialog'
import RemoveProductDialog from '../../RemoveProductDialog'
import RemoveStackDialog from '../../RemoveStackDialog'

const MyStackModals = ({
  addProductModalOpen,
  currentProduct,
  editProductModalOpen,
  getStack,
  manageUsersModalOpen,
  preselectedAddProductFilter,
  productFilters,
  products,
  removeProductDialogOpen,
  removeStackDialogOpen,
  selectedProductManagementDetail,
  setAddProductModalOpen,
  setCurrentProduct,
  setEditProductModalOpen,
  setManageUsersModalOpen,
  setPreselectedAddProductFilter,
  setRemoveProductDialogOpen,
  setRemoveStackDialogOpen,
  stack,
  user,
}) => {
  return (
    <>
      <Modal open={addProductModalOpen} setOpen={setAddProductModalOpen}>
        <AddProductModal
          preselectedAddProductFilter={preselectedAddProductFilter}
          setPreselectedAddProductFilter={setPreselectedAddProductFilter}
          stack={stack}
          getStack={getStack}
          products={products}
          productFilters={productFilters}
          setOpen={setAddProductModalOpen}
        />
      </Modal>
      <Modal open={editProductModalOpen} setOpen={setEditProductModalOpen}>
        <EditProductModal
          currentProduct={currentProduct}
          productManagementDetail={selectedProductManagementDetail}
          setOpen={setEditProductModalOpen}
          getStack={getStack}
          stack={stack}
        />
      </Modal>
      <Dialog open={removeProductDialogOpen} setOpen={setRemoveProductDialogOpen}>
        <RemoveProductDialog
          stack={stack}
          getStack={getStack}
          setCurrentProduct={setCurrentProduct}
          product={currentProduct}
          setOpen={setRemoveProductDialogOpen}
        />
      </Dialog>
      <Dialog open={removeStackDialogOpen} setOpen={setRemoveStackDialogOpen}>
        <RemoveStackDialog stack={stack} setOpen={setRemoveStackDialogOpen} />
      </Dialog>
      <Modal open={manageUsersModalOpen} setOpen={setManageUsersModalOpen}>
        <ManageUsersModal
          user={user}
          open={editProductModalOpen}
          setOpen={setEditProductModalOpen}
          stack={stack}
        />
      </Modal>
    </>
  )
}

export default MyStackModals
