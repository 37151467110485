import React, { useState, useEffect } from 'react'
import Rating from './Rating'
import Button from './Button'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import SurveyComment from './SurveyComment'
import { Transition } from '@headlessui/react'

const SurveyQuestion = ({ question, form }) => {
  const [commentsOpen, setCommentsOpen] = useState(question.type === 'open-ended' || false)
  const errors = form?.formState?.errors
  const ratingHasError = !!errors[`rate-${question.id}`]
  const commentHasError = !!errors[`comment-${question.id}`]

  useEffect(() => {
    setCommentsOpen(question.type === 'open-ended' || false)
  }, [question])

  return (
    <div className="py-12">
      <div className="flex flex-col md:flex-row justify-between gap-8 items-center">
        <div className="font-semibold">
          {question.question} {question.required && <span className="text-gray-700">*</span>}
        </div>
        {question.type === 'rating' && (
          <div className="flex flex-col lg:flex-row justify-between gap-4 lg:gap-8 items-center">
            <div className="-mt-6">
              <Rating
                questionId={question.id}
                form={form}
                required={question.required}
                hasError={ratingHasError}
              />
            </div>
            <div>
              <Button
                className="flex gap-2"
                color="white"
                onClick={() => setCommentsOpen(!commentsOpen)}
                type="button"
              >
                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-gray-500" />
                <span className="font-medium">Comment</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <Transition
        show={commentsOpen}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="mt-6">
          <SurveyComment
            questionId={question.id}
            form={form}
            required={question.type === 'open-ended' ? true : false}
            hasError={commentHasError}
          />
        </div>
      </Transition>
    </div>
  )
}

export default SurveyQuestion
