import React from 'react'
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip as ChartTooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(BarController, BarElement, CategoryScale, LinearScale, ChartTooltip)

const ProductByCategoryChart = ({ mostUsedProducts }) => {
  if (!mostUsedProducts.length) return

  const barChartData = {
    labels: mostUsedProducts?.map(mostUsedProduct => {
      return mostUsedProduct.name
    }),
    datasets: [
      {
        data: mostUsedProducts?.map(product => product.count),
        backgroundColor: interpolateColors('#4338ca', '#e0e7ff', mostUsedProducts?.length),
      },
    ],
  }

  const options = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <>
      <Bar data={barChartData} options={options} />
    </>
  )
}

function interpolateColors(color1, color2, steps) {
  // Convert hex colors to RGB
  const hexToRgb = hex => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return { r, g, b }
  }

  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  // Calculate the step size for each channel (R, G, B)
  const stepSize = {
    r: (rgb2.r - rgb1.r) / (steps + 1),
    g: (rgb2.g - rgb1.g) / (steps + 1),
    b: (rgb2.b - rgb1.b) / (steps + 1),
  }

  // Generate intermediate colors
  const intermediateColors = []
  for (let i = 0; i < steps; i++) {
    const r = Math.round(rgb1.r + stepSize.r * (i + 1))
    const g = Math.round(rgb1.g + stepSize.g * (i + 1))
    const b = Math.round(rgb1.b + stepSize.b * (i + 1))
    const hex = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`
    intermediateColors.push(hex)
  }

  return intermediateColors
}

export default ProductByCategoryChart
