import React, { useState } from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import { classNames } from '../../../utilities/general'
import Button from '../../Button'
import {
  ArrowsPointingInIcon,
  ClipboardDocumentListIcon,
  LightBulbIcon,
  ShieldCheckIcon,
} from '@heroicons/react/20/solid'

const BusinessProcessSummary = ({ survey, selectedBusinessProcess, isSiteAdmin }) => {
  const [loadingCommentRefresh, setLoadingCommentRefresh] = useState(false)

  const productCommentSummaries = survey.commentSummaries.find(
    commentSummary => commentSummary.businessProcessId === selectedBusinessProcess.id
  )

  let parsedSummaries
  try {
    parsedSummaries = JSON.parse(productCommentSummaries?.summaryText)
  } catch (e) {}

  const refreshCommentSummaries = async () => {
    setLoadingCommentRefresh(true)

    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    const restOperation = post({
      apiName: 'bordercrossRest',
      path: `/surveys/business-process-survey/updateSurveyCommentSummaries/${survey.id}/${selectedBusinessProcess.id}`,
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body: {},
      },
    })
    await restOperation.response

    setLoadingCommentRefresh(false)
    window.location.reload()
  }

  return (
    <>
      {parsedSummaries && (
        <div className="space-y-4">
          {parsedSummaries?.summary?.length > 0 && (
            <div>
              <div className="flex items-center gap-2">
                <div className="bg-gradient-to-r from-fuchsia-600 to-purple-600 p-2 rounded text-white">
                  <ClipboardDocumentListIcon className="h-5 w-5" />
                </div>
                <h3 className="font-semibold">Overview</h3>
              </div>
              <ul className="text-sm list-disc pl-8">
                {parsedSummaries?.summary?.map(summaryPoint => (
                  <li className="mt-2">{summaryPoint}</li>
                ))}
              </ul>
            </div>
          )}
          {parsedSummaries?.effectiveAspects?.length > 0 && (
            <div>
              <div className="flex items-center gap-2">
                <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-2 rounded text-white">
                  <ShieldCheckIcon className="h-5 w-5" />
                </div>
                <h3 className="font-semibold">What works well</h3>
              </div>

              <ul className="text-sm list-disc pl-8">
                {parsedSummaries?.effectiveAspects?.map(effectivePoint => (
                  <li className="mt-2">{effectivePoint}</li>
                ))}
              </ul>
            </div>
          )}
          {parsedSummaries?.challenges?.length > 0 && (
            <div>
              <div className="flex items-center gap-2">
                <div className="bg-gradient-to-r from-red-500 to-orange-500 p-2 rounded text-white">
                  <ArrowsPointingInIcon className="h-5 w-5" />
                </div>
                <h3 className="font-semibold">Challenges</h3>
              </div>
              <ul className="text-sm list-disc pl-8">
                {parsedSummaries?.challenges?.map(challengePoint => (
                  <li className="mt-2">{challengePoint}</li>
                ))}
              </ul>
            </div>
          )}
          {parsedSummaries?.userSuggestions?.length > 0 && (
            <div>
              <div className="flex items-center gap-2">
                <div className="bg-gradient-to-r from-amber-200 to-yellow-400 p-2 rounded text-white">
                  <LightBulbIcon className="h-5 w-5" />
                </div>
                <h3 className="font-semibold">Suggestions</h3>
              </div>

              <ul className="text-sm list-disc pl-8">
                {parsedSummaries?.userSuggestions?.map(suggestionPoint => (
                  <li className="mt-2">{suggestionPoint}</li>
                ))}
              </ul>
            </div>
          )}

          {isSiteAdmin && (
            <Button
              className="text-xs"
              color="gray"
              variant="soft"
              onClick={refreshCommentSummaries}
            >
              <ArrowPathIcon
                className={classNames(
                  loadingCommentRefresh && 'animate-spin',
                  'text-gray-700 h-4 w-4 mr-1'
                )}
              />{' '}
              Refresh
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default BusinessProcessSummary
