import React, { useState, useEffect } from 'react'
import { fetchAuthSession } from 'aws-amplify/auth'
import { get } from 'aws-amplify/api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  ArrowLeftIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline'
import CountUp from 'react-countup'

import LoadBox from '../../LoadBox'
import useStackList from '../../../hooks/useStackList'
import SurveyStatusDark from '../../StackStatusDark'
import ResultsHeatmap from './ResultsHeatmap'
import surveyQuestions from '../../data/surveyQuestions'
import BusinessProcessSummary from './BusinessProcessSummary'
import ResultsHeatmapKey from '../../ResultsHeatmapKey'
import SurveyAnswerRating from '../../SurveyAnswerRating'
import SurveyAnswerComment from '../../SurveyAnswerComment'
import { SparklesIcon } from '@heroicons/react/20/solid'
import Dialog from '../../Dialog'
import RemoveSurveyDialog from '../../RemoveSurveyDialog'
import MenuOptions from './MenuOptions'

const ProductSurveyDetailsPage = ({ cognitoUser, cognitoUserAttributes }) => {
  const [loadingSurvey, setLoadingSurveys] = useState()
  const [deleteSurveyDialogOpen, setDeleteSurveyDialogOpen] = useState(false)
  const [survey, setSurvey] = useState([])
  const { surveyUniqueCode } = useParams()
  const { loadingStackList, stackList } = useStackList(cognitoUser)
  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const getSurvey = async () => {
      setLoadingSurveys(true)

      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/business-process-survey/${surveyUniqueCode}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const survey = await response.body.json()
      setSurvey(survey)
      setLoadingSurveys(false)
    }

    getSurvey()
  }, [])

  // Select a business process from survey to default as selected
  useEffect(() => {
    if (!selectedBusinessProcess && survey?.businessProcessIds?.length) {
      const businessProcess = surveyQuestions.businessProcessSurvey.processes.find(
        process => process.id === survey?.businessProcessIds[0]
      )
      setSelectedBusinessProcess(businessProcess)
    }
  }, [survey])

  if (loadingSurvey || loadingStackList) return <LoadBox />

  const surveyStacks = stackList.filter(stack => survey.stackIds.includes(stack.id))

  const businessProcessesInSurvey = survey.businessProcessIds.map(businessProcessId => {
    return surveyQuestions.businessProcessSurvey.processes.find(
      process => process.id === businessProcessId
    )
  })

  const isSiteAdmin = cognitoUserAttributes?.isSiteAdmin

  const businessProcessHeatmapSummaries = survey.heatmapSummaries.find(
    heatmapSummary => heatmapSummary.businessProcessId === selectedBusinessProcess.id
  )
  let parsedHeatmapSummaries
  try {
    parsedHeatmapSummaries = JSON.parse(businessProcessHeatmapSummaries?.summaryText)
  } catch (e) {}

  let answersCount = 0
  let commentsCount = 0
  survey?.completions?.forEach(completion => {
    completion.answers.forEach(answer => {
      if (answer.comment || answer.rating) answersCount++
      if (answer.comment) commentsCount++
    })
  })

  const surveyCompletions = survey?.byBusinessProcess?.find(
    businessProcess => businessProcess.businessProcessId === selectedBusinessProcess.id
  )?.completions

  const businessProcessQuestions = surveyQuestions.businessProcessSurvey.processes.find(
    process => process.id === selectedBusinessProcess?.id
  )?.questions

  return (
    <div className="mx-auto max-w-8xl mb-10 bg-white">
      <div className="divide-y divide-gray-200 bg-indigo-1000 shadow">
        <div className="px-4 py-6 sm:py-8 sm:px-12">
          <div className="flex items-center gap-4 justify-between">
            <Link
              onClick={e => {
                e.preventDefault()
                navigate(
                  `/employee-insights/business-process-survey/list/${survey.stackIds[0]}/${selectedBusinessProcess.id}`
                )
              }}
              className="mb-4 inline-flex items-center rounded bg-indigo-1000 px-3.5 py-2.5 text-sm text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-blue-950"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
            </Link>
            <MenuOptions setDeleteSurveyDialogOpen={setDeleteSurveyDialogOpen} />
          </div>

          <div className="my-8 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <span className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">
                Business Process Survey
              </span>
              <h2 className="text-3xl mt-2 font-semibold tracking-tight text-indigo-300 sm:text-6xl">
                {selectedBusinessProcess.name}
              </h2>
              <div className="flex gap-2 items-center mt-2">
                <BuildingOfficeIcon className="h-8 w-8 text-white stroke-1" />
                <p className="text-xl text-white">
                  {surveyStacks.map(surveyStack => surveyStack.name).join(', ')}
                </p>
              </div>

              <dl className="flex flex-row gap-4 justify-between mt-14">
                <div className="flex flex-col-reverse">
                  <dt className="text-base text-white">Survey responses</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-indigo-300">
                    <CountUp end={survey?.completions?.length} duration={3} />
                  </dd>
                </div>

                <div className="flex flex-col-reverse">
                  <dt className="text-base text-white">Questions answered</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-green-400">
                    <CountUp end={answersCount} duration={3} />
                  </dd>
                </div>

                <div className="flex flex-col-reverse">
                  <dt className="text-base text-white">Comments received</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-yellow-300">
                    <CountUp end={commentsCount} duration={3} />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <div className="mt-6 mb-4 text-white">
                <SurveyStatusDark
                  dark={true}
                  surveyPath={`/ext/employee-insights/business-process-survey/${surveyUniqueCode}`}
                  closedDate={survey?.closedDate}
                  organisationName={surveyStacks[0]?.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:py-12 sm:px-12">
        {surveyCompletions?.length > 0 ? (
          <>
            {parsedHeatmapSummaries && (
              <div className="mb-14">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                  Summary
                </h2>

                <div className="bg-slate-50 rounded-lg mt-6 p-4 md:p-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {parsedHeatmapSummaries && (
                      <div className="mt-2">
                        <BusinessProcessSummary
                          survey={survey}
                          selectedBusinessProcess={selectedBusinessProcess}
                          isSiteAdmin={isSiteAdmin}
                        />
                      </div>
                    )}

                    <div>
                      <ResultsHeatmapKey />
                      <div className="mt-2">
                        <ResultsHeatmap
                          selectedBusinessProcess={selectedBusinessProcess}
                          survey={survey}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mb-14">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                Results
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                {businessProcessQuestions
                  ?.filter(question => question.type === 'rating')
                  .map(businessProcessQuestion => {
                    const answers = []
                    surveyCompletions.forEach(completion =>
                      completion.answers?.map(answer => {
                        if (answer.questionId === businessProcessQuestion.id) answers.push(answer)
                      })
                    )
                    const ratings = answers.map(answer => answer.rating)

                    return (
                      <SurveyAnswerRating
                        question={businessProcessQuestion.question}
                        ratings={ratings}
                      />
                    )
                  })}
              </div>
            </div>

            <div className="mb-14">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                Comments
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                {businessProcessQuestions
                  ?.filter(question =>
                    surveyCompletions.find(completion =>
                      completion.answers.find(
                        answer => answer.comment && question.id === answer.questionId
                      )
                    )
                  )
                  .map(businessProcessQuestion => {
                    const answers = []
                    surveyCompletions.forEach(completion =>
                      completion.answers?.map(answer => {
                        if (answer.questionId === businessProcessQuestion.id)
                          answers.push({
                            ...answer,
                            role: completion.role,
                          })
                      })
                    )
                    const comments = answers
                      .filter(answer => answer.comment)
                      .map(answer => {
                        return {
                          comment: answer.comment,
                          sentiment: answer.commentSentiment,
                          role: answer.role,
                        }
                      })

                    return (
                      <SurveyAnswerComment
                        question={businessProcessQuestion.question}
                        comments={comments}
                      />
                    )
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <ChatBubbleLeftEllipsisIcon className="max-w-sm max-h-sm text-slate-200" />
            <h2 className="text-2xl text-slate-400">No responses received</h2>
          </div>
        )}
      </div>
      <Dialog open={deleteSurveyDialogOpen} setOpen={setDeleteSurveyDialogOpen}>
        <RemoveSurveyDialog
          surveyId={survey.id}
          setOpen={setDeleteSurveyDialogOpen}
          surveyEndpointPath="business-process-survey"
        />
      </Dialog>
    </div>
  )
}

export default ProductSurveyDetailsPage
