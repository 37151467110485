import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get, post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import surveyQuestions from '../../data/surveyQuestions'
import SurveyListItem from './SurveyListItem'
import Button from '../../Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import TrendSection from './TrendSection'

const BusinessProcessSurveyListPage = ({ cognitoUser, stack }) => {
  const [loadingStackSurveys, setLoadingStackSurveys] = useState(true)
  const [stackSurveys, setStackSurveys] = useState([])
  const [moments, setMoments] = useState([])
  const [loadingMoments, setLoadingMoments] = useState(true)
  const { stackId, businessProcessId } = useParams()

  const businessProcess = surveyQuestions.businessProcessSurvey.processes.find(
    process => process.id === businessProcessId
  )

  useEffect(() => {
    const getStackSurveys = async () => {
      setLoadingStackSurveys(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const response = await get({
        apiName: 'bordercrossRest',
        path: `/surveys/business-process-survey/list/${stackId}/${businessProcessId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      }).response
      const stackSurveys = await response.body.json()
      const dateSortedSurveys = stackSurveys.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      getMoments()
      setStackSurveys(dateSortedSurveys)
      setLoadingStackSurveys(false)
    }

    getStackSurveys()
  }, [])

  useEffect(() => {
    getMoments()
  }, [])

  const getMoments = async () => {
    setLoadingMoments(true)
    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    const response = await get({
      apiName: 'bordercrossRest',
      path: `/surveys/business-process-survey/moments/${stackId}/${businessProcessId}`,
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
      },
    }).response

    const moments = await response.body.json()
    setMoments(moments)
    setLoadingMoments(false)
  }

  const updateMoments = async moments => {
    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

    return await post({
      apiName: 'bordercrossRest',
      path: `/surveys/business-process-survey/moments/${stackId}/${businessProcessId}`,
      options: {
        headers: {
          'jwt-token': 'Basic ' + accessToken,
        },
        body: {
          moments,
        },
      },
    }).response
  }

  const loading = loadingStackSurveys || loadingMoments

  const surveysWithFavourabilityData = stackSurveys.filter(
    survey => survey?.averageFavourablility?.total
  )

  return (
    <div className="bg-slate-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 py-10 lg:px-8">
        <div className="flex flex-col lg:flex-row gap-x-8 gap-y-4">
          <div className="w-full">
            <Button to={`/employee-insights?stackId=${stackId}`} variant="solid" color="white">
              <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
            </Button>

            <div className="mt-10">
              <div className="flex flex-wrap gap-x-6 gap-y-2 items-center">
                <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                  {businessProcess.name}
                </h1>
                <Button
                  variant="solid"
                  color="indigo"
                  to={`/employee-insights/business-process-survey-setup?businessProcessId=${businessProcessId}&stackId=${stackId}`}
                >
                  Create new survey
                </Button>
              </div>
              <p className="mt-2 text-lg leading-8 text-gray-600 max-w-2xl">
                {businessProcess.shortDescription}
              </p>
            </div>
          </div>
        </div>

        {loading ? (
          <LoadBox />
        ) : (
          <>
            <div className="mt-8">
              {surveysWithFavourabilityData.length > 0 && (
                <div className="w-full hidden md:block">
                  <TrendSection
                    stackSurveys={surveysWithFavourabilityData}
                    moments={moments}
                    updateMoments={updateMoments}
                    refetchMoments={getMoments}
                  />
                </div>
              )}
            </div>

            <div className="mt-8">
              <h2 className="text-2xl lg:text-3xl font-semibold mt-1 text-slate-900 group-hover:underline underline-offset-2 decoration-2">
                Surveys
              </h2>
              <ul className="mt-4 space-y-8">
                {stackSurveys.map(survey => {
                  return (
                    <div>
                      <SurveyListItem survey={survey} />
                    </div>
                  )
                })}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BusinessProcessSurveyListPage
