import React, { useState, useEffect } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { generateClient } from 'aws-amplify/api'
import { useLocation } from 'react-router-dom'

import DesktopFilters from './DesktopFilters'
import MobileFilterDialog from './MobileFilterDialog'
import MissingProductBanner from '../../MissingProductBanner'
import ProductsList from './ProductsList'
import { listSoftwareProducts, listProductFilters } from '../../../graphql/queries-static'
import LoadBox from '../../LoadBox'

const client = generateClient()

const ProductsPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [products, setProducts] = useState([])
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [filters, setFilters] = useState()
  const [filtersLoading, setFiltersLoading] = useState(true)
  const [productsLoading, setProductsLoading] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState([])
  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const filtersQueryParam = urlParams.getAll('filter')
    setSelectedFilters(filtersQueryParam)
  }, [])

  useEffect(() => {
    const getFilters = async () => {
      setFiltersLoading(true)
      const productFilters = await client.graphql({
        query: listProductFilters,
        authMode: 'userPool',
      })

      const sortedProductTags = productFilters.data.listProductTags.items.sort(
        (productTagA, productTagB) => {
          if (productTagA.name > productTagB.name) return 1
          if (productTagB.name > productTagA.name) return -1
          return 0
        }
      )

      const sortedProductCategories = productFilters.data.listProductCategories.items.sort(
        (productCategoryA, productCategoryB) => {
          if (productCategoryA.name > productCategoryB.name) return 1
          if (productCategoryB.name > productCategoryA.name) return -1
          return 0
        }
      )

      setFilters([
        {
          id: 'category',
          name: 'Category',
          options: sortedProductCategories,
        },
        {
          id: 'tag',
          name: 'Tags',
          options: sortedProductTags,
        },
      ])
      setFiltersLoading(false)
    }

    getFilters()
  }, [])

  useEffect(() => {
    const getProducts = async () => {
      setProductsLoading(true)
      const softwareProducts = await client.graphql({
        query: listSoftwareProducts,
        variables: {
          limit: 1000,
        },
        authMode: 'userPool',
      })

      const products = softwareProducts?.data?.listSoftwareProducts?.items

      const sortedProducts = products?.sort((productA, productB) => {
        if (productA.name > productB.name) return 1
        if (productB.name > productA.name) return -1
        return 0
      })

      setProducts(sortedProducts)
      setProductsLoading(false)
    }
    getProducts()
  }, [])

  const handleFilterChange = e => {
    const newSelectedFilters = [...selectedFilters]
    const changedFilter = e.target.value
    if (selectedFilters.includes(changedFilter)) {
      newSelectedFilters.splice(newSelectedFilters.indexOf(changedFilter), 1)
    } else {
      newSelectedFilters.push(changedFilter)
    }
    setSelectedFilters(newSelectedFilters)
  }

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  let filteredProducts = products
  if (searchTerm) {
    filteredProducts = filteredProducts.filter(product => {
      if (product?.name?.toLowerCase().includes(searchTerm?.toLowerCase()?.trim())) return true
      return false
    })
  }
  if (selectedFilters.length) {
    filteredProducts = filteredProducts.filter(product => {
      return selectedFilters.every(filterItem => {
        const matchesCategory = product?.productCategory?.id === filterItem
        const matchesTag = product?.productTags?.items?.some(tag => filterItem === tag.productTagId)
        return matchesCategory || matchesTag
      })
    })
  }

  return (
    <div>
      <div>
        {filters && (
          <MobileFilterDialog
            filters={filters}
            mobileFiltersOpen={mobileFiltersOpen}
            setMobileFiltersOpen={setMobileFiltersOpen}
            handleFilterChange={handleFilterChange}
            selectedFilters={selectedFilters}
          />
        )}

        <main className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="border-b border-gray-200 pb-10">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">Discover Products</h1>
            <p className="mt-4 text-base text-gray-500">
              An extensive catalogue of products to help you choose the right product for your
              organisation.
            </p>
            <div className="mt-6">
              <input
                name="productName"
                id="productName"
                className="block w-full rounded-md border-0 py-4 px-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6 sm:text-sm lg:text-2xl  "
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search for a product..."
              />
            </div>
          </div>

          <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">Filters</span>
                <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </button>
              {filters && (
                <DesktopFilters
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  selectedFilters={selectedFilters}
                />
              )}
            </aside>
            {/* Product grid */}
            <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
              {productsLoading ? (
                <LoadBox />
              ) : (
                <>
                  <ProductsList searchTerm={searchTerm} products={filteredProducts} />
                  <MissingProductBanner />
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default ProductsPage
