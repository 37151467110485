import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { percentage } from '../../../../utilities/general'

let sentimentColours = {
  border: {
    POSITIVE: '#4ade80',
    NEGATIVE: '#f87171',
    NEUTRAL: '#94a3b8',
    MIXED: '#94a3b8',
  },
  text: {
    POSITIVE: '#16a34a',
    NEGATIVE: '#dc2626',
    NEUTRAL: '#475569',
    MIXED: '#475569',
  },
  background: {
    POSITIVE: '#f0fdf4',
    NEGATIVE: '#fef2f2',
    NEUTRAL: '#f1f5f9',
    MIXED: '#f1f5f9',
  },
}

// Helper function to render table rows
const renderRow = ({ comment, sentiment, role }) => (
  <View
    style={{
      marginVertical: 4,
    }}
    wrap={false}
  >
    <Text style={{ fontSize: 11 }} wrap>
      "{comment}"
    </Text>
    <Text style={{ color: 'gray', fontSize: 10 }}>- {role}</Text>
  </View>
)

// The main SurveyResultBox component
const SurveyCommentsBox = ({ question, comments }) => {
  console.log()
  const positiveCommentsCount = comments.filter(comment => comment.sentiment === 'POSITIVE').length
  const negativeCommentsCount = comments.filter(comment => comment.sentiment === 'NEGATIVE').length

  const sentimentOrder = ['POSITIVE', 'NEUTRAL', 'MIXED', 'NEGATIVE']

  const sortedComments = comments.sort((a, b) => {
    return sentimentOrder.indexOf(a.sentiment) - sentimentOrder.indexOf(b.sentiment)
  })

  return (
    <>
      <View
        style={{
          marginBottom: 30,
        }}
      >
        {/* Table Header */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '1px solid #e5e7eb',
            paddingBottom: 10,
            marginBottom: 10,
          }}
          wrap={false}
        >
          <Text
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#111827',
              flex: 1,
              padding: 5,
              alignSelf: 'center', // Vertically align the question text
            }}
          >
            {question}
          </Text>
          <View
            style={{
              borderLeft: '1px solid #e5e7eb',
              textAlign: 'center',
              width: '20%',
              display: 'flex',
              alignItems: 'center', // Center content horizontally
              justifyContent: 'center', // Center content vertically
            }}
          >
            {positiveCommentsCount >= negativeCommentsCount ? (
              <>
                <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
                  {percentage(positiveCommentsCount, comments.length)}%
                </Text>
                <Text style={{ fontSize: 12, color: '#10b981' }}>Positive</Text>
              </>
            ) : (
              <>
                <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
                  {percentage(positiveCommentsCount, comments.length)}%
                </Text>
                <Text style={{ fontSize: 12, color: '#dc2626' }}>Negative</Text>
              </>
            )}
          </View>
        </View>
        {sortedComments.map(comment => renderRow(comment))}
      </View>
    </>
  )
}

export default SurveyCommentsBox
