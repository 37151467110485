import React from 'react'
import ProductBoxItem from './ProductListItem'

const ProductsList = ({ searchTerm, products }) => {
  return (
    <>
      {searchTerm && (
        <>
          {products.length ? (
            <>
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Products matching your search "{searchTerm}"
              </h2>
              <ul className="mt-4">
                {products.map(product => {
                  return <ProductBoxItem product={product} />
                })}
              </ul>
            </>
          ) : (
            <p className="my-8 text-2xl">No products matching your search term.</p>
          )}
        </>
      )}

      {!searchTerm && products && (
        <>
          <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            All products
          </h2>
          <ul className="mt-4">
            {products.map(product => {
              return <ProductBoxItem product={product} />
            })}
          </ul>
        </>
      )}
    </>
  )
}

export default ProductsList
