import React from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

import ContractBadge from '../../ContractBadge'

const ProductPanelContents = ({ productManagementDetail }) => {
  const contractStartDate =
    productManagementDetail?.contractStartDate &&
    new Date(productManagementDetail.contractStartDate)
  const contractEndDate =
    productManagementDetail?.contractEndDate && new Date(productManagementDetail.contractEndDate)

  return (
    <dl className="space-y-4">
      <div>
        <dt className="text-gray-900">Price</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.price && productManagementDetail?.pricingPlan ? (
            <>
              £{productManagementDetail.price}{' '}
              {productManagementDetail?.pricingPlan.toLowerCase().replaceAll('_', ' ')}
            </>
          ) : (
            <>-</>
          )}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Procured Internally or Externally</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.procuredInternally === false ||
          productManagementDetail?.procuredInternally === true ? (
            <>{productManagementDetail?.procuredInternally ? 'Internally' : 'Externally'}</>
          ) : (
            <>-</>
          )}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Contract Start Date</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.contractStartDate && productManagementDetail?.pricingPlan ? (
            <>{contractStartDate.toLocaleDateString()}</>
          ) : (
            <>-</>
          )}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Contract End Date</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.contractEndDate && productManagementDetail?.pricingPlan ? (
            <div className="mt-1">
              <ContractBadge contractEndDate={contractEndDate} size="sm" />
            </div>
          ) : (
            <>-</>
          )}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Notes</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.notes ? (
            <div className="mt-1">{productManagementDetail?.notes}</div>
          ) : (
            <>-</>
          )}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Data Protection Impact Assessment (DPIA)</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.DPIAStatus === 'Completed' && (
            <div className="mt-1">
              {' '}
              <CheckCircleIcon className="h-6 w-6 text-green-600" />
            </div>
          )}
          {productManagementDetail?.DPIAStatus === 'Not completed' && (
            <div className="mt-1">
              {' '}
              <XCircleIcon className="h-6 w-6 text-red-600" />
            </div>
          )}
          {productManagementDetail?.DPIAStatus !== 'Completed' &&
            productManagementDetail?.DPIAStatus !== 'Not completed' && <>-</>}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Clinical Risk Safety (DCB0160)</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.DCB0160Status === 'Completed' && (
            <div className="mt-1">
              {' '}
              <CheckCircleIcon className="h-6 w-6 text-green-600" />
            </div>
          )}
          {productManagementDetail?.DCB0160Status === 'Not completed' && (
            <div className="mt-1">
              {' '}
              <XCircleIcon className="h-6 w-6 text-red-600" />
            </div>
          )}
          {productManagementDetail?.DCB0160Status !== 'Completed' &&
            productManagementDetail?.DCB0160Status !== 'Not completed' && <>-</>}
        </dd>
      </div>

      <div>
        <dt className="text-gray-900">Data Sharing Agreement</dt>
        <dd className="text-gray-700">
          {productManagementDetail?.sharingAgreementStatus === 'Completed' && (
            <div className="mt-1">
              {' '}
              <CheckCircleIcon className="h-6 w-6 text-green-600" />
            </div>
          )}
          {productManagementDetail?.sharingAgreementStatus === 'Not completed' && (
            <div className="mt-1">
              {' '}
              <XCircleIcon className="h-6 w-6 text-red-600" />
            </div>
          )}
          {productManagementDetail?.sharingAgreementStatus !== 'Completed' &&
            productManagementDetail?.sharingAgreementStatus !== 'Not completed' && <>-</>}
        </dd>
      </div>
    </dl>
  )
}

export default ProductPanelContents
