import React from 'react'
import {
  ArrowRightIcon,
  ArrowPathIcon,
  BanknotesIcon,
  CircleStackIcon,
} from '@heroicons/react/20/solid'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { calculateStackCost, calculateProductsRequiringPricingDetails } from '../utilities/stacks'

const ProductManagementStats = ({ stack, pheDetails }) => {
  const stackManagementDetails = stack?.StackProductManagements?.items
  const totalProducts = stack.SoftwareProducts.items.length
  const productsRequiringManagementDetails = totalProducts - stackManagementDetails.length
  let productsRequiringContractEndDates = productsRequiringManagementDetails

  const patientCount = pheDetails?.population?.ListSize?.Val
  let totalCost = calculateStackCost(stack, stackManagementDetails, null, patientCount, false)
  let totalCostInternalOnly = calculateStackCost(
    stack,
    stackManagementDetails,
    null,
    patientCount,
    true
  )

  const productsRequiringPricingDetails = calculateProductsRequiringPricingDetails(
    stack,
    stackManagementDetails
  )

  const dueForRenewel = stackManagementDetails.filter(stackManagementDetail => {
    if (!stackManagementDetail.contractEndDate) {
      productsRequiringContractEndDates++
      return false
    }

    const productContractEndDate = new Date(stackManagementDetail.contractEndDate)
    const currentDate = new Date()
    const threeMonthsLater = new Date()
    threeMonthsLater.setMonth(currentDate.getMonth() + 3)

    return productContractEndDate >= currentDate && productContractEndDate <= threeMonthsLater
  })

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-blue-500 p-3">
              <CircleStackIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Products in your organisation
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-2 sm:pb-4">
            <p className="text-2xl font-semibold text-gray-900">{totalProducts}</p>
            {/* <p className="ml-2 flex items-baseline text-sm font-semibold">
                
            </p> */}

            {productsRequiringManagementDetails > 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-yellow-50 px-4 py-4 sm:px-6 text-yellow-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <ExclamationTriangleIcon className="h-4 w-4" aria-hidden="true" />
                    {productsRequiringManagementDetails} product
                    {productsRequiringManagementDetails > 1 ? 's' : ''} require details adding
                  </div>
                </div>
              </div>
            )}

            {productsRequiringManagementDetails === 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-green-50 px-4 py-4 sm:px-6 text-green-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <CheckCircleIcon className="h-4 w-4" aria-hidden="true" />
                    All products have management details added
                  </div>
                </div>
              </div>
            )}
          </dd>
        </div>

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-blue-500 p-3">
              <BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Total cost of your products
            </p>
          </dt>
          <dd className="ml-16 pb-2 sm:pb-4">
            <div className="flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">
                £{parseFloat(totalCost).toFixed(2)}
              </p>
              <p className="ml-1 flex items-baseline text-sm font-semibold">per year</p>
            </div>
            <p className="truncate text-sm font-medium text-gray-500 mt-2">
              Internally procured cost
            </p>
            <p>£{parseFloat(totalCostInternalOnly).toFixed(2)} per year</p>

            {productsRequiringPricingDetails > 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-yellow-50 px-4 py-4 sm:px-6 text-yellow-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <ExclamationTriangleIcon className="h-4 w-4" aria-hidden="true" />
                    {productsRequiringPricingDetails} product
                    {productsRequiringPricingDetails > 1 ? 's' : ''} require pricing details adding
                  </div>
                </div>
              </div>
            )}

            {productsRequiringPricingDetails === 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-green-50 px-4 py-4 sm:px-6 text-green-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <CheckCircleIcon className="h-4 w-4" aria-hidden="true" />
                    All products have pricing details added
                  </div>
                </div>
              </div>
            )}
          </dd>
        </div>

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-blue-500 p-3">
              <ArrowPathIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Products due for renewal in the next 3 months
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-2 sm:pb-4">
            <div>
              <p className="text-2xl font-semibold text-gray-900">{dueForRenewel.length}</p>
              <Link
                to={`/contracts/${stack.id}`}
                className="flex items-center text-sm font-semibold text-blue-600 hover:text-blue-900"
              >
                See Contract Timeline
                <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Link>
            </div>
            {productsRequiringContractEndDates > 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-yellow-50 px-4 py-4 sm:px-6 text-yellow-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <ExclamationTriangleIcon className="h-4 w-4" aria-hidden="true" />
                    {productsRequiringContractEndDates} product
                    {productsRequiringContractEndDates > 1 ? 's' : ''} require contract end dates
                    adding
                  </div>
                </div>
              </div>
            )}

            {productsRequiringContractEndDates === 0 && (
              <div className="absolute inset-x-0 bottom-0 bg-green-50 px-4 py-4 sm:px-6 text-green-700 font-medium">
                <div className="text-sm">
                  <div className="flex items-center gap-1">
                    <CheckCircleIcon className="h-4 w-4" aria-hidden="true" />
                    All products have contract end dates added
                  </div>
                </div>
              </div>
            )}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default ProductManagementStats
