import React from 'react'
import { classNames } from '../../../utilities/general'
import { surveyTags } from '../../data/surveyTags'

const SurveyTagItem = ({ tagName }) => {
  const tag = surveyTags.find(tag => tag.name === tagName)

  return (
    <span
      className={classNames(
        tag.textColour,
        tag.bgColour,
        'inline-flex gap-1 items-center rounded-md px-2 py-1 text-xs font-medium'
      )}
    >
      {tag.icon && <tag.icon className="h-4 w-4" />}
      <span>{tag.name}</span>
    </span>
  )
}

export default SurveyTagItem
