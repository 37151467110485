import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import 'chartjs-adapter-date-fns'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
  annotationPlugin
)

const TrendChart = ({ surveys, moments }) => {
  const barChartData = {
    labels: surveys?.map(survey => {
      return survey.createdAt
    }),
    datasets: [
      {
        label: 'Favourability %',
        data: surveys?.map(survey => {
          return (
            (survey?.averageFavourablility?.favourable / survey?.averageFavourablility?.total) * 100
          )
        }),
        pointRadius: 6,
        tension: 0.3,
        borderColor: '#4f46e5',
        pointBackgroundColor: '#4f46e5',
      },
    ],
  }

  const firstAndLastSurveyDistribution = calculateDaysBetweenSurveys(surveys)
  const xAxisUnit = getXAxisUnit(firstAndLastSurveyDistribution)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: xAxisUnit,
        },
        ticks: {
          source: 'auto',
          autoSkip: false,
          maxTicksLimit: 5,
        },
        bounds: 'ticks',
        offset: false,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        max: 125, // making room for "moments" labels above
        afterTickToLabelConversion: ctx => {
          ctx.ticks = []
          ctx.ticks.push({ value: 25, label: '25%' })
          ctx.ticks.push({ value: 50, label: '50%' })
          ctx.ticks.push({ value: 75, label: '75%' })
          ctx.ticks.push({ value: 100, label: '100%' })
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: context => {
            const date = new Date(context[0].parsed.x)
            const formattedDate = date.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })
            return formattedDate
          },
        },
      },
      annotation: {
        annotations: moments.map(moment => {
          return {
            adjustScaleRange: true,
            type: 'line',
            scaleID: 'x',
            value: moment.date,
            borderColor: '#94a3b8',
            borderWidth: 0.5,
            font: {
              weight: 'normal',
              size: '11',
            },
            label: {
              content: moment.description,
              display: true,
              backgroundColor: '#e0e7ff',
              color: 'black',
              yAdjust: -88,
            },
          }
        }),
      },
    },
  }

  return <Line data={barChartData} options={options} />
}

export default TrendChart

const calculateDaysBetweenSurveys = surveys => {
  if (!surveys?.length) return 0

  // Get the first and last survey dates
  const lastDate = new Date(surveys[0].createdAt)
  const firstDate = new Date(surveys[surveys.length - 1].createdAt)

  // Calculate the difference in time
  const timeDifference = lastDate - firstDate

  // Convert time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  return daysDifference
}

const getXAxisUnit = firstAndLastSurveyDistribution => {
  if (firstAndLastSurveyDistribution > 1095) return 'year'
  else return 'month'
}
