import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'primereact/treeselect'
import { useForm } from 'react-hook-form'
import { post } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { generateClient, get } from 'aws-amplify/api'
import 'primereact/resources/themes/lara-light-cyan/theme.css' // replace with my own styling for component

import LoadingIcon from './LoadingIcon'
import Button from './Button'
import ProgressStepper from './ProgressStepper'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { getAreaStructureNodes, getSelectedPracticeIds } from '../utilities/treeSelect'
import AddProductFilter from './AddProductFilter'
import { listSoftwareProducts, listProductFilters } from '../graphql/queries-static'
import ProductForm from './ProductForm'

const client = generateClient()

export default function ManageGroupProductsModal({ areaStructure, user, setOpen }) {
  const [selectedNodeKeys, setSelectedNodeKeys] = useState()
  const [loadingProducts, setLoadingAddProducts] = useState(false)
  const [productAdded, setProductAdded] = useState(false)
  const [addProductError, setAddProductError] = useState(false)
  const [step, setStep] = useState(1)
  const [products, setProducts] = useState([])
  const [productFilters, setProductFilters] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: {
      sharingAgreementStatus: 'Not required',
      DCB0160Status: 'Not required',
      DPIAStatus: 'Not required',
      procuredInternally: 'external',
    },
  })

  useEffect(() => {
    const getProducts = async () => {
      const softwareProducts = await client.graphql({
        query: listSoftwareProducts,
        variables: {
          limit: 1000,
        },
        authMode: 'userPool',
      })
      const sortedProducts = softwareProducts.data.listSoftwareProducts.items.sort(
        (productA, productB) => {
          if (productA.name > productB.name) return 1
          if (productB.name > productA.name) return -1
          return 0
        }
      )
      setProducts(sortedProducts)
    }
    getProducts()
  }, [])

  useEffect(() => {
    const getFilters = async () => {
      const productFilters = await client.graphql({
        query: listProductFilters,
      })

      const sortedProductTags = productFilters.data.listProductTags.items.sort(
        (productTagA, productTagB) => {
          if (productTagA.name > productTagB.name) return 1
          if (productTagB.name > productTagA.name) return -1
          return 0
        }
      )

      const sortedProductCategories = productFilters.data.listProductCategories.items.sort(
        (productCategoryA, productCategoryB) => {
          if (productCategoryA.name > productCategoryB.name) return 1
          if (productCategoryB.name > productCategoryA.name) return -1
          return 0
        }
      )

      setProductFilters([
        {
          id: 'category',
          name: 'Category',
          options: sortedProductCategories,
        },
        {
          id: 'tag',
          name: 'Tags',
          options: sortedProductTags,
        },
      ])
    }

    getFilters()
  }, [])

  const steps = [
    {
      id: '01',
      name: 'Select Organisations',
      onClick: () => setStep(1),
      status: step === 1 ? 'current' : 'complete',
    },
    {
      id: '02',
      name: 'Select Product',
      onClick: () => setStep(2),
      status: step === 1 ? 'upcoming' : step === 2 ? 'current' : 'complete',
    },
    {
      id: '03',
      name: 'Add Product Details',
      onClick: () => setStep(3),
      status: step === 3 ? 'current' : step < 3 ? 'upcoming' : 'complete',
    },
  ]

  const nodes = getAreaStructureNodes(areaStructure)
  const selectedPracticeIds = getSelectedPracticeIds(selectedNodeKeys, nodes)

  const handleAddProducts = async data => {
    setLoadingAddProducts(true)

    try {
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()

      let procuredInternally
      if (data?.procuredInternally === 'internal' || data?.procuredInternally === 'external') {
        procuredInternally = data.procuredInternally === 'internal' ? true : false
      }

      const body = {
        productManagementDetails: {
          DCB0160Status: data.DCB0160Status,
          DPIAStatus: data.DPIAStatus,
          sharingAgreementStatus: data.sharingAgreementStatus,
          contractStartDate: data.contractStartDate || null,
          contractEndDate: data.contractEndDate || null,
          price: data.price || null,
          pricingPlan: data.pricingPlan,
          procuredInternally: procuredInternally,
          notes: data.notes,
        },
        stackIds: selectedPracticeIds,
        productId: selectedProduct.id,
      }

      const restOperation = post({
        apiName: 'bordercrossRest',
        path: '/stacks/groupAddProducts',
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
          body,
        },
      })

      await restOperation.response
      setProductAdded(true)
    } catch {
      setAddProductError(true)
    }

    setLoadingAddProducts(false)
  }

  const ModalHeader = () => <h2 className="text-xl sm:text-4xl font-bold">Group Add Products</h2>

  if (productAdded) {
    return (
      <div className="max-h-[75vh] overflow-scroll">
        <ModalHeader />

        <div className="my-8">
          <ProgressStepper steps={steps} />
        </div>

        <h3 className="text-lg sm:text-2xl font-bold mb-2 mt-6">
          <div className="flex gap-2 items-center">
            <CheckCircleIcon className="h-8 w-8 text-green-600" />
            <span>Product successfully added</span>
          </div>
        </h3>

        <div className="w-full mt-5 sm:mt-6">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  if (addProductError) {
    return (
      <div className="max-h-[75vh] overflow-scroll">
        <ModalHeader />

        <h3 className="text-lg sm:text-2xl font-bold mb-2 mt-6">
          <div className="flex gap-2 items-center">
            <XCircleIcon className="h-8 w-8 text-red-600" />
            <span>Add products failed</span>
          </div>
        </h3>
        <p className="text-base mb-4">
          Please try again, if the issue continues please contact{' '}
          <a className="hover:text-gray-600 underline" href="mailto:contact@bordercross.health">
            contact@bordercross.health
          </a>
          .
        </p>

        <div className="w-full mt-5 sm:mt-6">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll">
        <ModalHeader />

        <div className="my-8">
          <ProgressStepper steps={steps} />
        </div>

        {step === 1 && (
          <>
            <h3 className="text-lg sm:text-xl font-bold mb-2">Select Organisations</h3>
            <p className="text-base mb-1">
              Select the organisations you'd like to add a product to. If the organisation already
              has this product, we'll overwrite the existing details they hold for it.
            </p>
            <TreeSelect
              value={selectedNodeKeys}
              onChange={e => setSelectedNodeKeys(e.value)}
              options={nodes}
              metaKeySelection={false}
              className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
              selectionMode="checkbox"
              display="chip"
            ></TreeSelect>
          </>
        )}

        {step === 2 && (
          <AddProductFilter
            header={<h3 className="text-lg sm:text-xl font-bold mb-2">Select Product</h3>}
            headerContainerStyles={'!py-2'}
            products={products}
            filters={productFilters}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}

        {step === 3 && (
          <>
            <h3 className="text-lg sm:text-xl font-bold mb-2">Add product details</h3>
            <ProductForm
              product={selectedProduct}
              control={control}
              watch={watch}
              register={register}
            />
          </>
        )}
      </div>
      <div className="mt-6 sm:mt-8 sm:flex sm:flex-row-reverse sm:gap-3">
        <div className="w-full">
          <Button
            className="w-full"
            color="blue"
            size="lg"
            onClick={() => {
              if (step === 3) {
                handleSubmit(handleAddProducts)()
                setStep(4)
              } else {
                setStep(step + 1)
              }
            }}
          >
            {step === 3
              ? `Add product to ${selectedPracticeIds.length} practice${
                  selectedPracticeIds.length > 1 ? 's' : ''
                }`
              : 'Next'}
            {loadingProducts && (
              <span className="ml-2">
                <LoadingIcon height="h-4" width="w-4" textColor="text-white" />
              </span>
            )}
          </Button>
        </div>
        <div className="w-full">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </>
  )
}
