import React from 'react'

import { classNames } from '../../../utilities/general'

const PcnContractBadge = ({ count, monthsRemaining, size = 'xs', type = 'badge' }) => {
  let badgeInfo = {}

  if (count === 0)
    badgeInfo = {
      label: `None expiring in ${monthsRemaining} months`,
      backgroundColour: 'bg-green-100',
      textColour: 'text-green-700',
      ring: 'ring-green-600/20',
    }
  else if (monthsRemaining === 3)
    badgeInfo = {
      label: `${count} expiring in ${monthsRemaining} months`,
      backgroundColour: 'bg-yellow-100',
      textColour: 'text-yellow-700',
      ring: 'ring-yellow-600/20',
    }
  else if (monthsRemaining === 12)
    badgeInfo = {
      label: `${count} expiring in ${monthsRemaining} months`,
      backgroundColour: 'bg-orange-100',
      textColour: 'text-orange-700',
      ring: 'ring-orange-600/20',
    }

  return (
    <>
      {type === 'badge' ? (
        <span
          className={classNames(
            'inline-flex items-center rounded-md font-medium ring-1 ring-inset',
            size === 'xs' && 'px-2 py-1 text-xs',
            size === 'sm' && 'px-2 py-1 text-sm',
            badgeInfo.backgroundColour,
            badgeInfo.textColour,
            badgeInfo.ring
          )}
        >
          {badgeInfo.label}
        </span>
      ) : (
        <span
          className={classNames(
            'inline-flex items-center font-medium',
            size === 'xs' && 'py-1 text-xs',
            size === 'sm' && 'py-1 text-sm',
            badgeInfo.textColour
          )}
        >
          {badgeInfo.label}
        </span>
      )}
    </>
  )
}

export default PcnContractBadge
