import { get } from 'aws-amplify/api'

export const getCQCRatingText = rating => {
  switch (rating) {
    case 1:
      return 'Inadequate'
    case 2:
      return 'Requires improvement'
    case 3:
      return 'Good'
    case 4:
      return 'Outstanding'
  }
}

export const getCQCRatingDescription = rating => {
  switch (rating) {
    case 1:
      return 'The service is performing badly and CQC have taken action against the person or organisation that runs it.'
    case 2:
      return 'The service is not performing as well as it should and CQC have been told the service how it must improve.'
    case 3:
      return "The service is performing well and meeting CQC's expectations."
    case 4:
      return 'The service is performing exceptionally well.'
  }
}

export const fetchPheData = async url => {
  try {
    const restOperation = get({
      apiName: 'orgData',
      path: `/phe?url=${encodeURIComponent(url)}`,
    })

    const { body } = await restOperation.response
    const response = await body.json()
    return response
  } catch (e) {}
}
