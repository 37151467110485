import React from 'react'

import ProductImage from '../../ProductImage'
import Button from '../../Button'

const ProductPanelHeader = ({
  currentProduct,
  setEditProductModalOpen,
  setRemoveProductDialogOpen,
  isReadAccess,
  hideButtons = false,
}) => {
  return (
    <div className="flex flex-col gap-2 items-center mt-6">
      <ProductImage softwareProduct={currentProduct} height="h-16" width="w-16" />
      <div className="font-bold text-lg">{currentProduct?.name}</div>
      {!isReadAccess && !hideButtons && (
        <div className="flex gap-4 justify-center mt-2">
          <Button
            onClick={() => setEditProductModalOpen(true)}
            className="min-w-[5rem]"
            color="gray"
            variant="soft"
          >
            Edit
          </Button>
          <Button onClick={() => setRemoveProductDialogOpen(true)} color="red" variant="soft">
            Remove
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProductPanelHeader
