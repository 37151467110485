import React, { useState, useEffect } from 'react'
import { generateClient } from 'aws-amplify/api'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { listOrganisations } from '../../graphql/queries-static'
import OrganisationBox from '../OrganisationBox'
import { titleCaseNHS } from '../../utilities/ods'
import LoadBox from '../LoadBox'
const client = generateClient()

// See https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?Roles=RO209
// for list of active NHS regions
const NHSRegions = [
  {
    Name: 'NORTH WEST COMMISSIONING REGION',
    OrgId: 'Y62',
  },
  {
    Name: 'NORTH EAST AND YORKSHIRE COMMISSIONING REGION',
    OrgId: 'Y63',
  },
  {
    Name: 'MIDLANDS COMMISSIONING REGION',
    OrgId: 'Y60',
  },
  {
    Name: 'EAST OF ENGLAND COMMISSIONING REGION',
    OrgId: 'Y61',
  },
  {
    Name: 'LONDON COMMISSIONING REGION',
    OrgId: 'Y56',
  },
  {
    Name: 'SOUTH WEST COMMISSIONING REGION',
    OrgId: 'Y58',
  },
  {
    Name: 'SOUTH EAST COMMISSIONING REGION',
    OrgId: 'Y59',
  },
]

const CommunityPage = () => {
  const [organisations, setOrganisations] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getOrganisations = async () => {
      setLoading(true)
      const organisationsResponse = await client.graphql({
        query: listOrganisations,
        variables: {
          limit: 100,
          filter: { adminPrivate: { ne: true } },
        },
      })
      setOrganisations(organisationsResponse.data.listOrganisations.items)
      setLoading(false)
    }
    getOrganisations()
  }, [])

  const convertRegionToHref = region => {
    return titleCaseNHS(region).replaceAll(' ', '-')
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Community Stacks
      </h1>
      <p className="mt-2 text-lg leading-8 text-gray-600">
        Discover the products other organisations use in their stacks
      </p>
      <div className="mt-4 flex flex-wrap gap-3 justify-center">
        {NHSRegions.map(region => (
          <a
            href={`#${convertRegionToHref(region.Name)}`}
            className="flex gap-1 rounded bg-blue-50 px-2 py-3 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
          >
            <MapPinIcon className="h-4 w-4 ml-1" />
            {titleCaseNHS(region.Name)}
          </a>
        ))}
      </div>
      {loading ? (
        <LoadBox />
      ) : (
        <div className="mt-6">
          {NHSRegions.map(region => {
            const regionOrganisations = organisations.filter(
              organisation => organisation.nhsRegionId === region.OrgId
            )
            return (
              <>
                <h2
                  id={convertRegionToHref(region.Name)}
                  className="flex gap-1 items-center text-xl sm:text-2xl font-bold tracking-tight text-gray-900 mt-12 mb-6"
                >
                  <MapPinIcon className="h-6 w-6 ml-1" />
                  {titleCaseNHS(region.Name)}
                </h2>
                {regionOrganisations.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {regionOrganisations.map(organisation => (
                      <OrganisationBox organisation={organisation} />
                    ))}
                  </div>
                ) : (
                  <p className="sm:text-lg">No organisations in this region yet.</p>
                )}
              </>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CommunityPage
