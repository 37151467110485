import React, { useEffect, useState } from 'react'
import { generateClient } from 'aws-amplify/api'

import {
  listSoftwareProducts,
  listProductFilters,
  listUsersWithSubscriptions,
  listSoftwareStacks,
} from '../../../graphql/queries-static'
import ManageProducts from './ManageProducts'
import Tabs from './Tabs'
import UserList from './UserList'
import Button from '../../Button'

const client = generateClient()

const AdminPage = () => {
  const [products, setProducts] = useState()
  const [productsLoading, setProductsLoading] = useState(true)
  const [usersLoading, setUsersLoading] = useState(true)
  const [productFilters, setProductFilters] = useState()
  const [users, setUsers] = useState()
  const [stacks, setStacks] = useState([])
  const [currentTab, setCurrentTab] = useState('Products')

  const getProducts = async () => {
    setProductsLoading(true)
    const softwareProducts = await client.graphql({
      query: listSoftwareProducts,
      variables: {
        limit: 1000,
      },
      authMode: 'userPool',
    })
    setProducts(softwareProducts.data.listSoftwareProducts.items)
    setProductsLoading(false)
  }

  useEffect(() => {
    getProducts()
  }, [])

  const getUsers = async () => {
    setUsersLoading(true)
    const users = await client.graphql({
      query: listUsersWithSubscriptions,
      variables: {
        limit: 1000,
      },
      authMode: 'userPool',
    })
    setUsers(users.data.listUsers.items)
    setUsersLoading(false)
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    const getFilters = async () => {
      const productFilters = await client.graphql({
        query: listProductFilters,
        authMode: 'userPool',
      })

      const sortedProductTags = productFilters.data.listProductTags.items.sort(
        (productTagA, productTagB) => {
          if (productTagA.name > productTagB.name) return 1
          if (productTagB.name > productTagA.name) return -1
          return 0
        }
      )

      const sortedProductCategories = productFilters.data.listProductCategories.items.sort(
        (productCategoryA, productCategoryB) => {
          if (productCategoryA.name > productCategoryB.name) return 1
          if (productCategoryB.name > productCategoryA.name) return -1
          return 0
        }
      )

      setProductFilters([
        {
          id: 'category',
          name: 'Category',
          options: sortedProductCategories,
        },
        {
          id: 'tag',
          name: 'Tags',
          options: sortedProductTags,
        },
      ])
    }

    getFilters()
  }, [])

  useEffect(() => {
    const getStacks = async () => {
      const softwareStacks = await client.graphql({
        query: listSoftwareStacks,
        variables: {
          limit: 1000,
        },
        authMode: 'userPool',
      })
      setStacks(softwareStacks.data.listSoftwareStacks.items)
    }
    getStacks()
  }, [])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8">
        <div className="flex gap-4">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Admin Dashboard
          </h1>
          <Button color="indigo" to="/updateStack">
            Add organisation
          </Button>
        </div>

        <div className="my-12">
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </div>
        <div className="my-12">
          {currentTab === 'Products' && (
            <ManageProducts
              products={products}
              productFilters={productFilters}
              getProducts={getProducts}
            />
          )}
          {currentTab === 'Users' && <UserList users={users} stacks={stacks} />}
        </div>
      </div>
    </>
  )
}

export default AdminPage
