import React from 'react'
import { useForm } from 'react-hook-form'

import NextButton from './NextButton'
import ProductImage from '../../ProductImage'
import Button from '../../Button'
import { XCircleIcon } from '@heroicons/react/20/solid'
import SurveyQuestion from '../../SurveyQuestion'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

const ProductQuestionStep = ({
  step,
  productQuestionStepEnd,
  setStep,
  updateAnswers,
  currentProduct,
  surveyQuestions,
}) => {
  const form = useForm()

  const onSubmit = async data => {
    await updateAnswers(data)

    form.reset()
    setStep(step + 1)
    window.scrollTo(0, 0)
  }

  const errors = !!Object.entries(form?.formState?.errors)?.length

  return (
    <>
      <div>
        <h3 className="mt-6 text-sm">This section is about: </h3>
        <div className="mt-2 flex gap-4 items-center">
          <div className="flex-shrink-0">
            <ProductImage softwareProduct={currentProduct} height="h-16" width="w-16" />
          </div>
          <div>
            <h2 className="font-semibold text-lg">{currentProduct.name}</h2>
            {currentProduct.link && (
              <div>
                <a
                  className="text-indigo-700 hover:text-indigo-800 hover:underline inline-flex items-center gap-1"
                  href={currentProduct.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{currentProduct.link}</span>
                  <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </a>
              </div>
            )}
            <p className="text-gray-700 text-sm mt-2 whitespace-pre-line">
              {currentProduct?.description}
            </p>
          </div>
        </div>
      </div>
      <form id={`product-${currentProduct.id}-form`} onSubmit={form.handleSubmit(onSubmit)}>
        <div className="divide-y divide-solid border-gray-400">
          {surveyQuestions?.map(productQuestion => {
            const question = {
              ...productQuestion,
              question: productQuestion.question.replace('<<softwareName>>', currentProduct.name),
            }
            return <SurveyQuestion question={question} product={currentProduct} form={form} />
          })}
        </div>

        {errors && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-base font-semibold text-red-700">
                  Fix the errors with your responses to continue
                </h3>
              </div>
            </div>
          </div>
        )}

        <div className="flex gap-4 items-center mt-8">
          <Button
            className="w-full max-w-xs px-3 py-3 "
            onClick={() => {
              setStep(step - 1)
              window.scrollTo(0, 0)
            }}
            color="white"
            type="button"
          >
            Back
          </Button>
          <NextButton text={step === productQuestionStepEnd ? 'Submit' : 'Next'} type={'submit'} />
        </div>
      </form>
    </>
  )
}

export default ProductQuestionStep
