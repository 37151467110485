import React from 'react'

const SurveyResultsOutput = ({
  survey,
  product: selectedProduct,
  surveyQuestions: productQuestions,
}) => {
  const surveyCompletions = survey?.byProduct?.find(
    product => product.productId === selectedProduct.id
  )?.completions

  return (
    <>
      <h2 className="">Full Survey results</h2>
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Strongly Disagree</th>
            <th>Disagree</th>
            <th>Neutral</th>
            <th>Agree</th>
            <th>Strongly Agree</th>
          </tr>
        </thead>

        <tbody>
          {productQuestions
            ?.filter(question => question.type === 'rating')
            .map(question => {
              const answers = []
              surveyCompletions?.forEach(completion =>
                completion.answers?.map(answer => {
                  if (answer.questionId === question.id) answers.push(answer)
                })
              )
              const ratings = answers.map(answer => answer.rating)

              return (
                <tr>
                  <td>
                    {question.question.replace('<<softwareName>>', selectedProduct.name)}
                    <br />
                  </td>
                  <td>
                    {ratings.filter(rating => rating === 1)?.length}
                    <br />
                  </td>
                  <td>
                    {ratings.filter(rating => rating === 2)?.length}
                    <br />
                  </td>
                  <td>
                    {ratings.filter(rating => rating === 3)?.length}
                    <br />
                  </td>
                  <td>
                    {ratings.filter(rating => rating === 4)?.length}
                    <br />
                  </td>
                  <td>
                    {ratings.filter(rating => rating === 5)?.length}
                    <br />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      <br />
    </>
  )
}

export default SurveyResultsOutput
