import React from 'react'

const MentalHealth = ({ organisation, pheDetails }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Mental Health
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div> */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    rowSpan="2"
                    className="bg-gray-100 py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Indicator
                  </th>
                  <th
                    scope="col"
                    rowSpan="2"
                    className="bg-gray-100 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Period
                  </th>
                  <th
                    scope="col"
                    colSpan="2"
                    className="bg-gray-100 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {organisation.name}
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    England
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    rowSpan="2"
                    className="bg-gray-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Count
                  </th>
                  <th
                    scope="col"
                    rowSpan="2"
                    className="bg-gray-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Value
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {pheDetails.mentalHealthIndicators.map(indicator => {
                  const englandAreaCode = 'E92000001'
                  const orgIndicatorData = indicator.Grouping.find(
                    group => group.ComparatorData.AreaCode === organisation.odsId
                  )
                  const englandIndicatorData = indicator.Grouping.find(
                    group => group.ComparatorData.AreaCode === englandAreaCode
                  )
                  const indicatorMetadata = pheDetails.mentalHealthIndicatorsMetadata[indicator.IID]

                  return (
                    <tr key={indicator.IID}>
                      <td className="px-3 py-4 text-sm font-medium text-gray-900 lg:w-1/3">
                        {indicatorMetadata.Descriptive.Name}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 lg:w-1/3">
                        {orgIndicatorData.Period}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 lg:w-1/3">
                        {orgIndicatorData.ComparatorData.Count}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 lg:w-1/3">
                        {orgIndicatorData.ComparatorData.ValF}
                        <span className="text-xs">
                          {indicatorMetadata.Unit.Label !== '%' ? ' ' : ''}
                          {indicatorMetadata.Unit.Label}
                        </span>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 lg:w-1/3">
                        {englandIndicatorData.ComparatorData.ValF}
                        <span className="text-xs">
                          {indicatorMetadata.Unit.Label !== '%' ? ' ' : ''}
                          {indicatorMetadata.Unit.Label}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentalHealth
