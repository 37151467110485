import React, { useState } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import { classNames, percentage } from '../utilities/general'

const SurveyAnswerRating = ({ question, ratings }) => {
  const [percentageHasBeenVisible, setPercentageHasBeenVisible] = useState(false)
  const [barChartHasBeenVisible, setBarChartHasBeenVisible] = useState(false)

  const totalRatings = ratings.length
  const generallyAgreePercentage = percentage(
    ratings.filter(rating => rating >= 4).length,
    totalRatings
  )
  const generallyDisagreePercentage = percentage(
    ratings.filter(rating => rating <= 2).length,
    totalRatings
  )

  const stronglyAgreeCount = ratings.filter(rating => rating === 5).length
  const agreeCount = ratings.filter(rating => rating === 4).length
  const neutralCount = ratings.filter(rating => rating === 3).length
  const disagreeCount = ratings.filter(rating => rating === 2).length
  const stronglyDisagreeCount = ratings.filter(rating => rating === 1).length

  return (
    <div className="bg-slate-50 rounded-lg">
      <div className="flex justify-between items-center gap-4 border-b border-slate-200">
        <div className="p-6">
          <h3 className="text-lg font-semibold tracking-tight text-gray-900">{question}</h3>
        </div>

        <VisibilitySensor
          partialVisibility={true}
          onChange={isVisible => {
            if (isVisible) setPercentageHasBeenVisible(true)
          }}
        >
          {generallyAgreePercentage >= generallyDisagreePercentage ? (
            <div className="flex flex-col items-center font-semibold border-l border-slate-200 min-w-[8rem] p-6">
              <div className="text-2xl">
                {percentageHasBeenVisible ? (
                  <>
                    <CountUp end={generallyAgreePercentage} duration={2} />%
                  </>
                ) : null}
              </div>
              <div className="text-green-600">Agreed</div>
            </div>
          ) : (
            <div className="flex flex-col items-center font-semibold border-l border-slate-200 min-w-[8rem] p-6">
              <div className="text-2xl">
                {percentageHasBeenVisible ? (
                  <>
                    <CountUp end={generallyDisagreePercentage} duration={2} />%
                  </>
                ) : null}
              </div>
              <div className="text-red-600">Disagreed</div>
            </div>
          )}
        </VisibilitySensor>
      </div>

      <VisibilitySensor
        partialVisibility={true}
        onChange={isVisible => {
          if (isVisible) setBarChartHasBeenVisible(true)
        }}
      >
        <div className="p-6 space-y-1">
          <BarChart
            colour="bg-green-600"
            label="Strongly Agree"
            count={stronglyAgreeCount}
            total={totalRatings}
            isVisible={barChartHasBeenVisible}
            index={0}
          />
          <BarChart
            colour="bg-green-500"
            label="Agree"
            count={agreeCount}
            total={totalRatings}
            isVisible={barChartHasBeenVisible}
            index={1}
          />
          <BarChart
            colour="bg-yellow-400"
            label="Neutral"
            count={neutralCount}
            total={totalRatings}
            isVisible={barChartHasBeenVisible}
            index={2}
          />
          <BarChart
            colour="bg-red-400"
            label="Disagree"
            count={disagreeCount}
            total={totalRatings}
            isVisible={barChartHasBeenVisible}
            index={3}
          />
          <BarChart
            colour="bg-red-600"
            label="Strongly Disagree"
            count={stronglyDisagreeCount}
            total={totalRatings}
            isVisible={barChartHasBeenVisible}
            index={4}
          />
        </div>
      </VisibilitySensor>
    </div>
  )
}

const BarChart = ({ colour, label, count, total, isVisible, index }) => (
  <div className="flex gap-4 justify-between items-center">
    <div className="flex rounded overflow-hidden h-6 w-full relative">
      <div
        className={classNames(isVisible && 'animate-progress', 'absolute bg-slate-200 h-6')}
        style={{ animationDelay: `${index * 100}ms` }}
      ></div>
      <div className="absolute" style={{ width: percentage(count, total) + '%' }}>
        <div
          className={classNames(colour, isVisible && 'animate-progress', 'h-6')}
          style={{ animationDelay: `${600 + index * 100}ms` }}
        ></div>
      </div>
    </div>

    <div className="flex-shrink-0 min-w-[9rem]">
      <div className="grid grid-cols-[1fr_min-content] gap-2">
        <div className="text-right text-gray-600 text-sm">{label}</div>
        <div className="text-left font-semibold text-gray-900 text-sm">{count}</div>
      </div>
    </div>
  </div>
)

export default SurveyAnswerRating
