import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

import LoadBox from '../../LoadBox'
import ProductListItem from './ProductListItem'
import Button from '../../Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import useProducts from '../../../hooks/useProducts'

const ProductSurveyProductListPage = ({ cognitoUser, stack }) => {
  const [loadingStackSurveys, setLoadingStackSurveys] = useState()
  const [stackSurveys, setStackSurveys] = useState([])
  const { loadingProducts, products } = useProducts()
  const { stackId } = useParams()

  useEffect(() => {
    const getStackSurveys = async () => {
      setLoadingStackSurveys(true)
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString()
      const restOperation = get({
        apiName: 'bordercrossRest',
        path: `/surveys/productSurvey/list/${stackId}`,
        options: {
          headers: {
            'jwt-token': 'Basic ' + accessToken,
          },
        },
      })
      const response = await restOperation.response
      const stackSurveys = await response.body.json()
      const dateSortedSurveys = stackSurveys.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      setStackSurveys(dateSortedSurveys)
      setLoadingStackSurveys(false)
    }

    getStackSurveys()
  }, [])

  const loading = loadingStackSurveys || loadingProducts

  console.log(stackSurveys, 'surveys,,,,')

  const productsSurveyed = []
  stackSurveys.forEach(survey => {
    if (!productsSurveyed.includes(survey.productIds[0]))
      productsSurveyed.push(survey.productIds[0])
  })

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8 bg-slate-50">
      <Button to={`/employee-insights?stackId=${stackId}`} variant="solid" color="white">
        <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
      </Button>

      <div className="flex flex-col md:flex-row gap-4 justify-between align-start mt-10">
        <div className="flex-shrink-0">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-center">
            <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Digital Infrastructure
            </h1>
            <Button
              variant="solid"
              color="indigo"
              to={`/employee-insights/product-survey-setup?stackId=${stackId}`}
            >
              Create new survey
            </Button>
          </div>
          <p className="mt-2 text-lg leading-8 text-gray-600 max-w-2xl">
            Digital infrastructure refers to the foundational IT systems and technologies that
            support healthcare operations, enabling efficient data management, communication, and
            patient care.
          </p>
        </div>
      </div>

      {loading ? (
        <LoadBox />
      ) : (
        <div className="mt-8">
          <h2 className="text-2xl lg:text-3xl font-semibold mt-1 text-slate-900 group-hover:underline underline-offset-2 decoration-2">
            Products
          </h2>
          <ul className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 auto-cols-min">
            {productsSurveyed.map(productId => {
              const product = products.find(product => product.id === productId)

              const surveyCount = stackSurveys.filter(survey => {
                return survey.productIds[0] === productId
              })?.length

              return (
                <div>
                  <ProductListItem stackId={stackId} surveyCount={surveyCount} product={product} />
                </div>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ProductSurveyProductListPage
