import { CheckIcon } from '@heroicons/react/24/solid'
import { classNames } from '../utilities/general'

// FORMAT OF STEPS TO PASS IN
// const steps = [
//     { id: 'Step 1', name: 'Job details', onClick: () => {}, status: 'complete' },
//     { id: 'Step 2', name: 'Application form', onClick: () => {}, status: 'current' },
//     { id: 'Step 3', name: 'Preview', onClick: () => {}, status: 'upcoming' },
//   ]

export default function ProgressStepper({ steps, clickable = true }) {
  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <button onClick={step.onClick} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    className={classNames(
                      clickable && 'group-hover:bg-indigo-800',
                      'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600'
                    )}
                  >
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </button>
            ) : step.status === 'current' ? (
              <button
                onClick={step.onClick}
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </button>
            ) : (
              <button onClick={step.onClick} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    className={classNames(
                      clickable && 'group-hover:border-gray-400',
                      'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300'
                    )}
                  >
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span
                    className={classNames(
                      clickable && 'group-hover:text-gray-900',
                      'ml-4 text-sm font-medium text-gray-500'
                    )}
                  >
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
