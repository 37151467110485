import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { generateClient } from 'aws-amplify/api'

import MyDashboardProductTiles from '../MyDashboardProductTiles'
import { getOrganisation, listProductCategories } from '../../graphql/queries-static'
import LoadBox from '../LoadBox'
import { titleCaseNHS, getAddress } from '../../utilities/ods'
const client = generateClient()

const OrganisationPage = () => {
  const { organisationId } = useParams()
  const [organisation, setOrganisation] = useState()
  const [loadingOrg, setLoadingOrg] = useState(true)
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [productCategories, setProductCategories] = useState([])

  useEffect(() => {
    const fetchOrganisation = async () => {
      setLoadingOrg(true)
      const organisation = await client.graphql({
        query: getOrganisation,
        variables: {
          id: organisationId,
        },
      })
      setOrganisation(organisation.data.getOrganisation)
      setLoadingOrg(false)
    }
    fetchOrganisation()
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      setLoadingCategories(true)
      const productCategories = await client.graphql({
        query: listProductCategories,
      })

      const sortedProductCategories = productCategories.data.listProductCategories.items.sort(
        (productCategoryA, productCategoryB) => {
          if (productCategoryA.name > productCategoryB.name) return 1
          if (productCategoryB.name > productCategoryA.name) return -1
          return 0
        }
      )

      setProductCategories(sortedProductCategories)
      setLoadingCategories(false)
    }

    getCategories()
  }, [])

  if (loadingOrg || loadingCategories) return <LoadBox />

  if (organisation) {
    const stack = organisation?.SoftwareStacks?.items?.[0]

    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
        <div className="flex flex-wrap gap-3 items-center">
          <h1 className="font-bold tracking-tight text-gray-900 text-3xl sm:text-4xl">
            {titleCaseNHS(organisation.name)}
          </h1>
          {organisation.pcnName && (
            <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-0.5 font-medium text-green-700">
              {titleCaseNHS(organisation.pcnName)}
            </span>
          )}
        </div>
        <div className="mt-2 max-w-2xl text-lg sm:text-xl leading-6 text-gray-500">
          {titleCaseNHS(organisation.icbName)}
        </div>
        <div className="grid grid-cols-3 gap-2 my-6">
          <div className="col-span-3">
            <MyDashboardProductTiles stack={stack} productCategories={productCategories} />
          </div>
        </div>
      </div>
    )
  }
}

export default OrganisationPage
