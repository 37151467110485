import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { classNames } from '../../../utilities/general'

const CategoryInput = ({ product, categories, register, setValue }) => {
  const [query, setQuery] = useState('')

  const filteredCategories =
    query === ''
      ? categories
      : categories.filter(category => {
          return category?.name?.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      as="div"
      by={'id'}
      defaultValue={product?.productCategory}
      onChange={category => setValue('productCategory', category)}
    >
      <Combobox.Label className="block sm:text-base font-medium leading-6 text-gray-900">
        Category *
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          {...register('category', { required: true })}
          autoComplete="off"
          required
          className="w-full rounded-md border-0 bg-white py-3 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
          onChange={event => setQuery(event.target.value)}
          displayValue={category => category?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredCategories?.length > 0 && (
          <Combobox.Options className="absolute z-[10000] mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
            {filteredCategories.map(category => (
              <Combobox.Option
                key={`${category.id}-category-combo-option`}
                value={category}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div>
                      <div className="flex items-center">
                        <span
                          className={classNames('truncate text-base', selected && 'font-semibold')}
                        >
                          {category.name}
                        </span>
                      </div>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default CategoryInput
