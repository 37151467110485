import React from 'react'
import { toTitleCase } from '../../../../utilities/general'

const SurveyCommentsOutput = ({
  survey,
  product: selectedProduct,
  surveyQuestions: productQuestions,
}) => {
  const surveyCompletions = survey?.byProduct?.find(
    product => product.productId === selectedProduct.id
  )?.completions

  return (
    <>
      <h2 className="">Survey comments</h2>
      <br />
      {productQuestions
        ?.filter(question =>
          surveyCompletions.find(completion =>
            completion.answers.find(answer => answer.comment && question.id === answer.questionId)
          )
        )
        .map(question => {
          const answers = []
          surveyCompletions.forEach(completion =>
            completion.answers?.map(answer => {
              if (answer.questionId === question.id)
                answers.push({
                  ...answer,
                  role: completion.role,
                })
            })
          )
          const comments = answers
            .filter(answer => answer.comment)
            .map(answer => {
              return {
                comment: answer.comment,
                sentiment: answer.commentSentiment,
                role: answer.role,
              }
            })

          return (
            <>
              <h3>{question.question.replace('<<softwareName>>', selectedProduct.name)}</h3>
              {comments.map(({ comment, sentiment, role }) => {
                return (
                  <p>
                    <strong className="font-semibold">
                      {role}{' '}
                      {sentiment ? (
                        <span className="capitalize">({toTitleCase(sentiment)})</span>
                      ) : (
                        ''
                      )}
                    </strong>
                    : "{comment}"
                  </p>
                )
              })}
              <br />
            </>
          )
        })}
    </>
  )
}

export default SurveyCommentsOutput
