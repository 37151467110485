import React, { useState } from 'react'
import { BoltIcon, IdentificationIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Lottie from 'react-lottie-player'

import { titleCaseNHS, getAddress } from '../../utilities/ods'
import LoadBox from '../LoadBox'
import { CQCRating, MentalHealth, Summary, Tabs } from './'
import Tooltip from '../Tooltip'

import wandLottieJson from '../../lottieFiles/magicWand.json'

// function convertEnumToTitleCase(enumString) {
//   return enumString.charAt(0).toUpperCase() + enumString.slice(1).toLowerCase();
// }

const OrgStats = ({ organisation, pheDetails, loading }) => {
  const [currentTab, setCurrentTab] = useState('Summary')

  // const icb = odsRelationships?.find((relation) => {
  //   return relation.Roles.Role.find((role) => {
  //     return role.Status === "Active" && role.id === "RO318"; // RO318 is the ICB role
  //   });
  // });

  // const pcn = odsRelationships?.find((relation) => {
  //   return relation.Roles.Role.find((role) => {
  //     return role.Status === "Active" && role.id === "RO272"; // RO272 is the Primary Care Network role
  //   });
  // });

  if (!organisation) return

  if (loading) {
    return (
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex justify-between">
            <div className="px-4 sm:px-0">
              <div className="flex items-baseline">
                <h3 className="text-2xl font-semibold leading-7 text-gray-900">
                  {organisation.name}
                </h3>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-col gap-4">
              <Lottie loop animationData={wandLottieJson} play style={{ width: 100 }} />
              <p>Insights loading...</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex gap-2 flex-col md:flex-row justify-between">
          <div className="">
            <div className="flex flex-wrap gap-2 items-center">
              <h3 className="text-2xl font-semibold leading-7 text-gray-900">
                {organisation.name}
              </h3>
              {organisation.pcnName && (
                <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-0.5 text-sm font-medium text-green-700">
                  {titleCaseNHS(organisation.pcnName)}
                </span>
              )}
            </div>
            {organisation.icbName && (
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                {titleCaseNHS(organisation.icbName)}
              </p>
            )}
            {organisation.addressLine1 && (
              <div className="flex text-sm mt-6">
                <MapPinIcon className="h4 w-4 mr-1" />
                <span>{getAddress(organisation)}</span>
              </div>
            )}
            {organisation.telephone && (
              <div className="flex text-sm mt-2">
                <PhoneIcon className="h4 w-4 mr-1" />
                <span>{organisation.telephone}</span>
              </div>
            )}
            {organisation.odsId && (
              <div className="flex text-sm mt-2">
                <IdentificationIcon className="h4 w-4 mr-1" />
                <Tooltip
                  overlay={
                    <div className="text-base">Organisation Identification Service (ODS) ID</div>
                  }
                >
                  <div>
                    <span>{organisation.odsId}</span>
                    <InformationCircleIcon className="h-4 w-4 text-gray-500 inline ml-1 mb-1" />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          {pheDetails?.populationSummary && <CQCRating pheDetails={pheDetails} />}
        </div>
        {pheDetails?.populationSummary && pheDetails?.population && (
          <>
            <div className="mt-6">
              <div className="my-2">
                <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
              </div>
              {currentTab === 'Summary' && <Summary pheDetails={pheDetails} />}
              {currentTab === 'Mental Health' && (
                <MentalHealth organisation={organisation} pheDetails={pheDetails} />
              )}
            </div>

            <div className="text-gray-500 italic mt-5 text-sm flex gap-1 items-center">
              <BoltIcon className="h-4 w-4 text-amber-500" /> Powered by official data from NHS
              Digital and Public Health England
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default OrgStats
