import React from 'react'
import {
  Chart,
  Colors,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip as ChartTooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import Tooltip from './Tooltip'

Chart.register(Colors, BarController, BarElement, CategoryScale, LinearScale, Legend, ChartTooltip)

const PopulationPyramid = ({ populationDetails }) => {
  const barChartData = {
    labels: populationDetails.Labels,
    datasets: [
      {
        label: 'Male',
        backgroundColor: '#3b82f6',
        data: populationDetails?.Values[1].map(k => -k),
      },
      {
        label: 'Female',
        backgroundColor: '#c4b5fd',
        data: populationDetails?.Values[2],
      },
    ],
  }

  const options = {
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          beginAtZero: true,
          fontSize: 13,
          callback: v => {
            return v < 0 ? -v + '%' : v + '%'
          },
        },
      },
      y: {
        reverse: true,
        stacked: true,
        ticks: {
          beginAtZero: true,
          fontSize: 13,
        },
        position: 'right',
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        intersect: false,
        callbacks: {
          label: function (context) {
            const positiveOnly = context.raw < 0 ? -context.raw : context.raw
            let retStr = ''
            if (context.datasetIndex === 0) {
              retStr += `Male: ${positiveOnly.toString()}%`
            } else {
              retStr += `Female: ${positiveOnly.toString()}%`
            }
            return retStr
          },
        },
      },
    },
  }

  return (
    <>
      <div className="text-sm">
        Patient population by age
        <Tooltip
          overlay={
            <div className="text-base">
              <div>Number of males and females registered with a practice, by 5 year age band.</div>
              <div className="text-gray-500 mt-2">Source: NHS digital</div>
            </div>
          }
        >
          <InformationCircleIcon className="h-5 w-5 text-gray-500 inline ml-1 mb-1" />
        </Tooltip>
      </div>
      <div>
        <Bar data={barChartData} options={options} />
      </div>
    </>
  )
}

export default PopulationPyramid
