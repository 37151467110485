import React, { useState } from 'react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import FavourabilityChart from '../../FavourabilityChart'
import SurveyTagItem from './SurveyTagItem'

const SurveysGridItem = ({
  icon,
  name,
  description,
  newSurveyLink,
  latestResultsSummary,
  surveyCount,
  viewSurveyLink,
  surveyPreviewModal,
  tags,
  isReadAccess,
}) => {
  const [surveyPreviewModalOpen, setSurveyPreviewModalOpen] = useState(false)

  const previewModal =
    surveyPreviewModal &&
    React.cloneElement(surveyPreviewModal, {
      modalOpen: surveyPreviewModalOpen,
      setModalOpen: setSurveyPreviewModalOpen,
    })

  return (
    <div className="rounded overflow-hidden flex flex-col items-stretch">
      <div className="bg-white p-6 sm:p-8 flex-grow flex flex-col justify-between">
        <div>
          <div className="flex justify-end gap-2">
            {tags.map(tag => (
              <SurveyTagItem tagName={tag} />
            ))}
          </div>
          <div className="flex items-start gap-3 min-h-[12rem] mt-2">
            <div className="bg-indigo-100 rounded-full h-10 w-10 flex items-center justify-center text-indigo-600 flex-shrink-0">
              <icon.iconSolid className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-xl lg:text-2xl font-semibold mt-1 text-slate-900">{name}</h3>
              <p className="text-sm lg:text-sm text-slate-500 mt-1">{description}</p>
              {!isReadAccess && (
                <Link
                  className="flex gap-1 items-center py-2 mt-1 text-sm font-semibold text-indigo-600"
                  to={newSurveyLink}
                >
                  Create new survey <ArrowRightIcon className="h-4 w-4" />
                </Link>
              )}
            </div>
          </div>
        </div>

        {!!latestResultsSummary?.total && (
          <div className="min-h-[6rem] p-6 bg-indigo-50 rounded mt-4">
            <p className="text-slate-800 text-base font-semibold flex gap-2 items-center flex-wrap">
              Latest survey results
              <Link
                className="text-sm font-medium hover:underline underline-offset-2 flex gap-1 items-center text-gray-600"
                to={viewSurveyLink}
              >
                View all
                <ArrowRightIcon className="h-4 w-4" />
              </Link>
            </p>

            <div className="text-green-600 text-sm mt-2">Favourable responses</div>
            <div className="mt-1">
              <FavourabilityChart
                favourable={latestResultsSummary.favourable}
                neutral={latestResultsSummary.neutral}
                unfavourable={latestResultsSummary.unfavourable}
                total={latestResultsSummary.total}
              />
            </div>
          </div>
        )}

        <div className="flex gap-2 mt-4 ">
          {surveyCount ? (
            <Button className="w-full" variant="solid" color="indigo" to={viewSurveyLink}>
              View {surveyCount} survey{surveyCount > 1 ? 's' : ''}
            </Button>
          ) : (
            <>
              {!isReadAccess ? (
                <Button className="w-full" variant="soft" color="indigo" to={newSurveyLink}>
                  Get started
                </Button>
              ) : null}
            </>
          )}

          <Button
            className="w-full"
            variant="soft"
            color="gray"
            onClick={() => setSurveyPreviewModalOpen(true)}
          >
            Preview Survey
          </Button>
        </div>
      </div>
      {previewModal}
    </div>
  )
}

export default SurveysGridItem
