import React, { useState } from 'react'

const CopyToClipboardDark = ({ label, text }) => {
  const [textCopied, setTextCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    setTextCopied(true)
    setTimeout(() => {
      setTextCopied(false)
    }, 2000)
  }

  return (
    <div className="grid grid-cols-8 gap-2 w-full">
      <label htmlFor="clipboard-copy" className="sr-only">
        {label}
      </label>
      <input
        id="clipboard-copy"
        type="text"
        className="col-span-6 border border-gray-700 bg-blue-950 text-white text-sm rounded-lg focus:ring-indigo-400 focus:border-indigo-400 block w-full p-2.5"
        value={text}
        readonly
      />
      <button
        onClick={handleCopy}
        className="col-span-2 text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-indigo-700 items-center inline-flex justify-center"
      >
        {!textCopied && <span id="default-message">Copy</span>}
        {textCopied && (
          <span id="success-message" className="inline-flex items-center">
            <svg
              className="w-3 h-3 text-white me-1.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            Copied!
          </span>
        )}
      </button>
    </div>
  )
}

export default CopyToClipboardDark
