import React, { useState, useEffect } from 'react'
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { generateClient } from 'aws-amplify/api'
import 'rc-tooltip/assets/bootstrap.css'
import { defaults } from 'chart.js'

import './App.css'
import StackTypePage from './components/pages/StackTypePage'
import MyStackDashboard from './components/pages/MyStackDashboard/MyStackDashboard'
import OrgSummaryPage from './components/pages/OrgSummaryPage'
import CommunityPage from './components/pages/CommunityPage'
import ProductsPage from './components/pages/ProductsPage'
import ProductPage from './components/pages/ProductPage'
import PricingPage from './components/pages/PricingPage'
import InvitedPage from './components/pages/InvitedPage'
import ErrorBoundary from './components/pages/ErrorBoundary'
import AccountPage from './components/pages/AccountPage'
import OrganisationPage from './components/pages/OrganisationPage'
import SurveysPage from './components/pages/SurveysPage'
import ProductSurveySetupPage from './components/pages/ProductSurveySetupPage'
import ProductSurveyListPage from './components/pages/ProductSurveyListPage/index.js'
import BusinessProcessSurveySetupPage from './components/pages/BusinessProcessSurveySetupPage'
import ProductSurveyDetailsPage from './components/pages/ProductSurveyDetailsPage'
import BusinessProcessSurveyDetailsPage from './components/pages/BusinessProcessSurveyDetailsPage'
import ContractsTimelinePage from './components/pages/ContractsTimelinePage'
import BusinessProcessSurveyListPage from './components/pages/BusinessProcessSurveyListPage/index.js'
import ProductSurveyProductListPage from './components/pages/ProductSurveyProductListPage/index.js'
import '@aws-amplify/ui-react/styles.css'
import Navigation from './components/Navigation'
import { AdminPage } from './components/pages/AdminPage'

import { listUsersWithSubscriptions } from './graphql/queries-static'
import LoadBox from './components/LoadBox'
import NotFound from './components/pages/NotFoundPage.js'
const client = generateClient()

// Set chart.js font defaults
defaults.font.family = "'Inter', 'Roboto', Arial, sans-serif"

function App({ signOut, cognitoUser }) {
  const [cognitoUserAttributes, setCognitoUserAttributes] = useState()
  const [user, setUser] = useState()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(location.search)
      const inviteId = urlParams.get('invite')
      if (inviteId) {
        navigate(`/invite/${inviteId}`)
      }
    }
  }, [location, user])

  useEffect(() => {
    const fetchCognitoUserDetails = async () => {
      const groups = (await fetchAuthSession()).tokens?.accessToken?.payload['cognito:groups']

      const cognitoUserAttributes = await fetchUserAttributes()
      setCognitoUserAttributes({
        ...cognitoUserAttributes,
        groups,
        isSiteAdmin: groups?.includes('siteAdmin'),
      })
    }
    fetchCognitoUserDetails()
  }, [])

  useEffect(() => {
    const getUsersWithSubscriptions = async () => {
      const usersResponse = await client.graphql({
        query: listUsersWithSubscriptions,
        variables: {
          filter: {
            owner: { eq: cognitoUser.userId },
          },
        },
        authMode: 'userPool',
      })
      const user = usersResponse.data.listUsers.items[0]

      if (!user) {
        throw new Error('Failed to fetch user')
      } else {
        setUser(usersResponse.data.listUsers.items[0])
      }
    }
    getUsersWithSubscriptions()
  }, [])

  // if (user) {
  //   const hasNoSubscription = !user.userSubscription;
  //   const isNotOnPricingPage = location.pathname !== "/pricing";

  //   if (isNotOnPricingPage && hasNoSubscription) {
  //     navigate(`/pricing`);
  //   }

  //   // what to do when the subscripotion is expired? - subscriptionExpired var
  //   // const currentDate = new Date();
  //   // const subscriptionExpired =
  //   //   user?.userSubscription?.currentPeriodEnd > currentDate.toISOString();
  // }

  return (
    <div>
      <Navigation
        cognitoUser={cognitoUser}
        cognitoUserAttributes={cognitoUserAttributes}
        signOut={signOut}
      />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Navigate to="employee-insights" />} />
          {/* <Route path="updateStack/:stackId" element={<UpdateStack />} /> */}
          <Route
            path="updateStack"
            element={
              <StackTypePage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="dashboard"
            element={<MyStackDashboard user={user} cognitoUser={cognitoUser} />}
          />
          <Route
            path="dashboard/summary"
            element={
              <OrgSummaryPage
                cognitoUser={cognitoUser}
                user={user}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights"
            element={
              <SurveysPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights/product-survey-setup"
            element={<ProductSurveySetupPage cognitoUser={cognitoUser} />}
          />
          <Route
            path="employee-insights/product-survey/:surveyUniqueCode"
            element={
              <ProductSurveyDetailsPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights/product-survey/list/:stackId"
            element={
              <ProductSurveyProductListPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights/product-survey/list/:stackId/:productId"
            element={
              <ProductSurveyListPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights/business-process-survey-setup"
            element={<BusinessProcessSurveySetupPage cognitoUser={cognitoUser} />}
          />
          <Route
            path="employee-insights/business-process-survey/list/:stackId/:businessProcessId"
            element={
              <BusinessProcessSurveyListPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route
            path="employee-insights/business-process-survey/:surveyUniqueCode"
            element={
              <BusinessProcessSurveyDetailsPage
                cognitoUser={cognitoUser}
                cognitoUserAttributes={cognitoUserAttributes}
              />
            }
          />
          <Route path="community" element={<CommunityPage />} />
          <Route path="invite/:inviteId" element={<InvitedPage />} />
          <Route path="organisation/:organisationId" element={<OrganisationPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="product/:productId" element={<ProductPage />} />
          {/* <Route
          path="pricing"
          element={
            <PricingPage cognitoUserAttributes={cognitoUserAttributes} />
          }
        /> */}
          <Route path="contracts/:stackId" element={<ContractsTimelinePage />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="account" element={<AccountPage user={user} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </div>
  )
}

export default App
