import React from 'react'

const SurveyHazardLogOutput = ({ survey }) => {
  let summary
  try {
    summary = JSON.parse(survey?.clinicalSafetySummary)
  } catch (e) {}

  return <div dangerouslySetInnerHTML={{ __html: summary?.Hazard_Table }}></div>
}

export default SurveyHazardLogOutput
