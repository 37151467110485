import React from 'react'

import { classNames } from '../utilities/general'
import { calculateContractWarningLevel } from '../utilities/stacks'

const ContractBadge = ({ contractEndDate, size = 'xs' }) => {
  const contractWarningLevel = calculateContractWarningLevel(contractEndDate)

  return (
    <>
      <span
        className={classNames(
          'inline-flex items-center rounded-md font-medium ring-1 ring-inset',
          size === 'xs' && 'px-2 py-1 text-xs',
          size === 'sm' && 'px-2 py-1 text-sm',
          contractWarningLevel.backgroundColour,
          contractWarningLevel.textColour,
          contractWarningLevel.ring
        )}
      >
        {contractEndDate.toLocaleDateString()}
      </span>
      <br />
      <span
        className={classNames(
          'mt-1',
          size === 'xs' && 'text-xs',
          size === 'sm' && 'text-sm',
          contractWarningLevel.textColour
        )}
      >
        {contractWarningLevel.label}
      </span>
    </>
  )
}

export default ContractBadge
